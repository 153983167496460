(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.constant('PermissionFlags', {
			READ: 0,
			WRITE: 1,
			READ_INCLUDE_ALL: 2,
			WRITE_INCLUDE_ALL: 3
		})
		.constant('DXPSyncModes', {
			DISABLED: 1,
			ENABLED: 2,
			DIAGNOSTIC: 3
		})
		.constant('EntityTypes', {
			COMPANY: 'company',
			SITE: 'site'
		})
		.constant('EntityTypeIDs', {
			COMPANY: 1,
			STATE: 2,
			ASSESSOR: 3,
			COLLECTOR: 4,
			SITE: 5,
			PARCEL: 6,
			ASSESSMENT: 7,
			APPEAL: 8,
			TAX_BILL: 9,
			FILING: 10,
			PAYMENT: 11,
			ANNUAL_YEAR: 12,
			BILL_CLUSTER: 13,
			REFUND: 14,
			INTAKE_ITEM: 15,
			USER: 16,
			CONTACT: 17,
			PAYMENTPACKAGE: 19,
			INVOICE: 21,
            ASSET: 22,
            FORM: 23,
            FORMREVISION: 24,
            FACTORTABLE: 25,
			FILINGBATCH: 26,
			INTAKE_BATCH: 27,
			TAXAUTHORITY: 28,
			TAXRATEAREA: 29,
			BUDGET: 31,
			ACCOUNTINGPERIOD: 32,
            ALLOCATION: 33,
			PAYMENTBATCH: 41
		})
		.constant('EntityTypeNames', {
			1: 'Company',
			2: 'State',
			3: 'Assessor',
			4: 'Collector',
			5: 'Site',
			6: 'Parcel',
			7: 'Assessment',
			8: 'Appeal',
			9: 'Tax Bill',
			10: 'PP Compliance',
			11: 'Tax Payment',
			12: 'Annual Year',
			13: 'Bill Cluster',
			14: 'Refund',
			15: 'Intake',
			16: 'User',
			17: 'Contact',
			19: 'PaymentPackage',
			21: 'Invoice',
			22: 'Asset',
            23: 'Form',
            24: 'Form Revision',
            25: 'Factor Table',
            26: 'Filing Batch',
            27: 'Intake Batch',
            28: 'Tax Authority',
            29: 'Tax Rate Area',
            31: 'Budget',
            32: 'Accounting Period',
            33: 'Allocation',
			41: 'Payment Batch'
		})
		.constant('EntityTypeLevels', {
			1: 7,
			5: 5,
			6: 4,
			7: 3,
			8: 3,
			9: 2,
			10: 1,
			14: 3,
			31: 3
		})
		.constant('PropertyTypes', {
			RE: {
				propTypeName: 'Real Estate',
				propTypeAbbr: 'RE',
				sequence: 1,
				propertyTypeID: 1
			},
			PP: {
				propTypeName: 'Personal',
				propTypeAbbr: 'PP',
				sequence: 2,
				propertyTypeID: 2
			},
			MN: {
				propTypeName: 'Mineral',
				propTypeAbbr: 'MN',
				sequence: 3,
				propertyTypeID: 3
			},
			CA: {
				propTypeName: 'Centrally Assessed',
				propTypeAbbr: 'CA',
				sequence: 4,
				propertyTypeID: 4
			}
		})
		.constant('PropCharNames', {
			SQFT: 'Imps. SqFt',
			UNITS: 'Units',
			ACREAGE: 'Land Acreage',
		})
		.constant('ActivityPanelModes', {
			totalFMV: 'totalFMV',
			totalFMVPerSqFt: 'totalFMVPerSqFt',
			totalFMVPerUnit: 'totalFMVPerUnit',
			landFMVPerSqFt: 'landFMVPerSqFt',
			landFMVPerAcre: 'landFMVPerAcre',
			totalTaxes: 'totalTaxes',
			recentAppeals: 'recentAppeals',
		})
		.constant('Roles', {
			// ADDNEWCOMPANY: 'AddNewCompany',
			ASSESSOREDIT: 'AssessorEdit',
			ASSESSORVIEW: 'AssessorView',
			COLLECTOREDIT: 'CollectorEdit',
			COLLECTORVIEW: 'CollectorView',
			COMMENTSEDIT: 'CommentsEdit',
			COMMENTSVIEW: 'CommentsView',
			COMPANYVIEW: 'CompanyView',
			// CONTACTVIEW: 'ContactView',
			// CONTACTEDIT: 'ContactEdit',
			//CONTACTROLEEDIT: 'ContactRoleEdit',
			CONTACTROLEVIEW: 'ContactRoleView',
            MANAGEAPIKEYS: 'ManageAPIKeys',
			PROPCHAREDIT: 'PropCharEdit',
			PROPCHARVIEW: 'PropCharView',
			// AGENTINFORMATIONEDIT: 'AgentInformationEdit',
			// AGENTINFORMATIONVIEW: 'AgentInformationView',
			RYANPRIVATEITEMSVIEW: 'RyanPrivateItemsView',
			RYANPRIVATEITEMSEDIT: 'RyanPrivateItemsEdit',
			STATEEDIT: 'StateEdit',
			STATEVIEW: 'StateView',
			//TEAMSETUPVIEW: 'TeamSetupsView',
			// TEAMSETUPSEDIT: 'TeamSetupsEdit',
			//USERGROUPSEDIT: 'UserGroupsEdit',
			//USERGROUPSVIEW: 'UserGroupsView',
			USERSEDIT: 'UsersEdit',
			USERSVIEW: 'UsersView',
            SYSTEMSEARCHESEDIT: 'EditSystemSearches',
            //COMPLIANCEFEATURESVIEW: 'ComplianceFeaturesView',
            COMPLIANCESETUPSVIEW: 'ComplianceSetupsView',
            COMPLIANCESETUPSEDIT: 'ComplianceSetupsEdit',
			ALLOWDATAIMPORTS: 'AllowDataImports',
			// ALLOWMOVECOPY: 'AllowMoveCopy',
            ADMINOPERATIONSVIEW: 'AdminOperationsView',
			ADMINOPERATIONSEDIT: 'AdminOperationsEdit',
            FORMSUNDERDEVELOPMENTVIEW: 'FormsUnderDevelopmentView',
			//COMPANYDATAIMPORTSVIEW: 'CompanyDataImportsView',
			USERREPORTSALLOW: 'UserReportsAllow',
			TAXRATESETUP: 'TaxRateSetup',
			INSTANCEADMIN: 'InstanceAdmin',
            SUPPORT: 'Support',
            ALLOWMANAGESYSTEM: 'AllowManageSystem',
            LICENSEDDOCUMENTSERVICES: 'LicensedDocumentServices'
        })
		.constant('InstanceRights', {
			ADDNEWCOMPANY: 'AddNewCompany',
			// ASSESSOREDIT: 'AssessorEdit',
			// ASSESSORVIEW: 'AssessorView',
			// COLLECTOREDIT: 'CollectorEdit',
			// COLLECTORVIEW: 'CollectorView',
			// COMMENTSEDIT: 'CommentsEdit',
			// COMMENTSVIEW: 'CommentsView',
			// COMPANYVIEW: 'CompanyView',
			CONTACTVIEW: 'ContactView',
			CONTACTEDIT: 'ContactEdit',
			MANAGECONSULTINGENGAGEMENT:'ManageConsultingEngagement',
			// CONTACTROLEEDIT: 'ContactRoleEdit',
			// CONTACTROLEVIEW: 'ContactRoleView',
            // MANAGEAPIKEYS: 'ManageAPIKeys',
			// PROPCHAREDIT: 'PropCharEdit',
			// PROPCHARVIEW: 'PropCharView',
			AGENTINFORMATIONEDIT: 'AgentInformationEdit',
			AGENTINFORMATIONVIEW: 'AgentInformationView',
			// RYANPRIVATEITEMSVIEW: 'RyanPrivateItemsView',
			// RYANPRIVATEITEMSEDIT: 'RyanPrivateItemsEdit',
			// STATEEDIT: 'StateEdit',
			// STATEVIEW: 'StateView',
			TEAMSETUPSVIEW: 'TeamSetupsView',
			TEAMSETUPSEDIT: 'TeamSetupsEdit',
			USERGROUPSEDIT: 'UserGroupsEdit',
			USERGROUPSVIEW: 'UserGroupsView',
			USERSEDIT: 'UsersEdit',
			USERSVIEW: 'UsersView',
            // SYSTEMSEARCHESEDIT: 'EditSystemSearches',
            COMPLIANCEFEATURESVIEW: 'ComplianceFeaturesView',
            // COMPLIANCESETUPSVIEW: 'ComplianceSetupsView',
            // COMPLIANCESETUPSEDIT: 'ComplianceSetupsEdit',
			// ALLOWDATAIMPORTS: 'AllowDataImports',
			ALLOWMOVECOPY: 'AllowMoveCopy',
            // ADMINOPERATIONSVIEW: 'AdminOperationsView',
			// ADMINOPERATIONSEDIT: 'AdminOperationsEdit',
            // FORMSUNDERDEVELOPMENTVIEW: 'FormsUnderDevelopmentView',
			COMPANYDATAIMPORTSVIEW: 'CompanyDataImportsView',
			// USERREPORTSALLOW: 'UserReportsAllow',
			// TAXRATESETUP: 'TaxRateSetup',
			PRIVATEITEMSEDIT: 'PrivateItemsEdit',
			PRIVATEITEMSVIEW: 'PrivateItemsView',
			MANAGEUSERSETUP:'ManageUserSetup',
			VALUATIONTEMPLATEEDIT: 'ValuationTemplateEdit',
			VALUATIONTEMPLATEVIEW: 'ValuationTemplateView',
            INSTANCEDATAIMPORTS: 'InstanceDataImports'
        })
		.constant('EfActions', {
			ADD: 'add',
			DELETE: 'delete',
			UPDATE: 'update'
		})
		.constant('ImageURLs', {
			ENTITY_MAP: '/images/googlemaps-icon.png',
			ATTACHMENTS_IMAGES: '/images/image_attachment.png',
			ATTACHMENTS_ALL_NONE: '/images/attachment_empty.png',
			ATTACHMENTS_ALL_SOME: '/images/attachment_some.png',
			ATTACHMENTS_IMAGES_ICON: 'fa-files-o',
			ATTACHMENTS_ALL_NONE_ICON: 'fa-paperclip',
			ATTACHMENTS_ALL_SOME_ICON: 'fa-files-o'
		})
		.constant('UserSettings', {
			ADVANCED_SEARCH: 10,
			QUICK_SEARCH: 11
		})
		.constant('AdvancedSearchCategoryTypes', {
			SYSTEM: 0,
			CUSTOM: 1,
			COMPANY: 2
		})
		.constant('DefaultDisplayTypes', {
			CRITERIA: 0,
			RESULTS: 1
		})
		.constant('AdvancedSearchDataTypes', {
			VARCHAR: 1,
			SMALLINT: 2,
			INT: 3,
			DECIMAL: 4,
			MONEY: 5,
			BIT: 6,
			DATETIME2: 7,
			PICKLIST: 8,
			TEXT_ALIGN_LEFT: 10,
			TEXT_ALIGN_RIGHT: 11,
			TEXT_ALIGN_CENTER: 12,
			NUMBER_0: 20,
			NUMBER_1: 21,
			NUMBER_2: 22,
			NUMBER_3: 23,
			NUMBER_0_NOGROUPING: 24,
			CURRENCY_0: 30,
			CURRENCY_2: 32,
			PERCENT_0: 40,
			PERCENT_1: 41,
			PERCENT_2: 42,
			PERCENT_3: 43,
			PERCENT_4: 44,
			PERCENT_5: 45,
			PERCENT_6: 46,
			YESNO: 50,
			DATE: 60,
			DATETIME: 61,
			ATTACHMENTICONOPENPREVIEW: 70
		})
		.constant('ActionViewCategoryTypes', {
			SYSTEM: 0,
			CUSTOM: 1
		})
		.constant('ReportCategoryTypes', {
			SYSTEM: 0,
			CUSTOM: 1
		})
		.constant('WorkflowUserTypes', {
			NA: 0,
			ACCOUNT_HANDLER: 1,
			JURISDICTION_SPECIALIST: 2,
			USER: 3,
			CONSULTANT:4
		})
		.constant('TaskActionTypes', {
			COMPLETETASK: 3,
			RESCINDTASK: 4,
			REASSIGNTASK: 5,
			INSERTNEWAFTERTHISTASK: 6,
			DELETETASK: 7,
			SKIPTASK: 8,
			INSERTNEWBEFORETHISTASK: 9,
			GETINSERTOPTIONS: 10
		})
		.constant('TaskTypes', {
			INFORMALHEARING: 8,
			FORMALHEARING: 9,
			SUBMITEVIDENCE: 27,
            INVOICEAPPEALSAVINGS: 34
		})
        .constant('TaskSeriesTypes', {
            PPRETURN: 1,
            ASMTAPPEALREVIEW: 2,
            APPEALPURSUIT: 3,
            REFUND: 4,
            TAXPAYMENT: 6,
            DOCUMENTINTAKE: 7,
            MISCFILING: 8,
            CLIENTINVOICE: 9,
            FREEPORTFILING: 10,
            ABATEMENTFILING: 11,
            POLLUTIONCONTROLFILING: 12
        })
		.constant('ActionViewCategoryTypes', {
			SYSTEM: 0,
			CUSTOM: 1
		})
		.constant('ActionViewOrganizeByTypes', {
			URGENCY: 1,
			DUEDATE: 2,
			LASTACTIVITYDATE: 3
		})
		.constant('ActionViewGroupByTypes', {
			TASKTYPE: {
				id: 10001,
				displayName: 'Task Type'
			},
			/*TASKCATEGORY: {
				id: null,
				displayName: 'Task Category'
			},*/
			ASSIGNEENAME: {
				id: 10002,
				displayName: 'Assignee Name'
			},
			ASSIGNEETEAM: {
				id: 10003,
				displayName: 'Assignee Team'
			},
			RESPONSIBLENAME: {
				id: 10004,
				displayName: 'Responsible Name'
			},
			RESPONSIBLETEAM: {
				id: 10005,
				displayName: 'Responsible Team'
			}

		})
		.constant('ActionViewDisplayTypes', {
			FILTER: 0,
			OVERVIEW: 1,
			DETAILED: 2
		})
		.constant('DocumentExceptionActions', {
			ADD_COMMENT: 1,
			REASSIGN_ITEM: 2,
			ESCALATE_ITEM: 3,
			REJECT_ITEM: 4,
			CLOSE_ITEM: 5,
			REQUEST_RESEARCH: 6,
			CLEAR_EXCEPTION: 7,
			BOUNCE_REJECTED: 8,
			REQUEST_TAX_RATE_SETUPS: 9
		})
		.constant('ProcessingExceptionStatus', {
			ADDEDINERROR: 1,
			ESCALATED: 2,
			REJECTED: 3,
			RESEARCHREQUESTED: 4
		})
		.constant('ProcessingExceptionStatusText', {
			1: 'Added in Error',
			2: 'Escalated',
			3: 'Rejected',
			4: 'Research Requested',
			5: 'Rates Requested'
		})
		// If these ever change, be sure to also change them in common/Message-Box/messagebox.service.ts
		.constant('MessageBoxButtons', {
			OK: 1,
			OKCancel: 2,
			YesNo: 3,
			YesNoCancel: 4
		})
		// If these ever change, be sure to also change them in common/Message-Box/messagebox.service.ts
		.constant('MessageBoxResult', {
			OK: 1,
			Cancel: 2,
			Yes: 3,
			No: 4
		})
		.constant('RevisionChangeTypes', {
			ORIGINAL_TO_FINAL: 1,
			FINAL: 2,
			OTHER: 3,
			NO_CHANGE: 4
		})
		.constant('TransmittalOutputTypes', {
			PAPER_TRANSMITTAL: {
				id: 1,
				name: 'Paper Transmittal',
				abbr: 'PT'
			},
			DIGITAL_TRANSMITTAL: {
				id: 2,
				name: 'Digital Transmittal',
				abbr: 'DT'
			},
			AP_FEED: {
				id: 3,
				name: 'AP Feed',
				abbr: 'AP'
			},
			BILL_PAY: {
				id: 4,
				name: 'Bill Pay',
				abbr: 'BP'
			}
		})
		.constant('TransmittalGroupBys', {
			SUBSIDIARY_COLLECTOR_SITE_DUEDATE: {
				id: 1,
				name: 'Subsidiary / Collector / Site / Due Date'
			},
			COLLECTOR_SITE_DUEDATE: {
				id: 2,
				name: 'Collector / Site / Due Date'
			},
			SUBSIDIARY_COLLECTOR_DUEDATE: {
				id: 3,
				name: 'Subsidiary / Collector / Due Date'
			},
			COLLECTOR_DUEDATE: {
				id: 4,
				name: 'Collector / Due Date'
			}
		})
		.constant('ClientServicesEnum', {
			ComplianceReturns: {
				id: 1,
				name: 'Compliance Returns'
			},
			Assessments: {
				id: 2,
				name: 'Assessments'
			},
			Appeals: {
				id: 3,
				name: 'Appeals'
			},
			TaxBills: {
				id: 4,
				name: 'Tax Bills'
			},
			TaxBillTransmit: {
				id: 5,
				name: 'Tax Bill - Transmit'
			}
		})
		.constant('CommentTypeNames', {
			1: 'Budget',
			2: 'PSR',
			3: 'Appeal Recommendation',
			4: 'DRTT',
            5: 'Accrual Export/Transmit',
            6: 'Import'
		})
		.constant('CommentTypeIDs', {
			BUDGET: 1,
			PSR: 2
		})
        .constant(
            'TaskActionViewContextOption', {
                ProcessDocument: 1,
                Reassign: 2,
                AddComment: 3,
                Duplicate: 4,
                AppealWarranted: 5,
                AppealNotWarranted: 6,
                CompleteFileAppeal: 7,
                DocumentException: 8,
                ChangeAppealDeadline: 9,
                ChangeFilingDeadline: 10,
                ChangeSubmitEvidenceDate: 11,
                ChangeInformalHearingDate: 12,
                ChangeFormalHearingDate: 13,
                ChangePaymentDueDate: 14,
                ChangeIntakeItemDueDate: 15,
                ChangeComplianceFilingDueDate: 16,
                Transmit: 17,
                ReviewPayment: 18,
                ReReviewPayment: 19,
                CreateInvoice: 20,
                PrepareDraftInvoice: 21,
                ReviewDraftInvoice: 22,
                PreviewThenTransferInvoice: 23,
                TransferInvoice: 24,
                ChangeInvoiceDueDate: 25,
                Skip: 26,
                SetDocumentRetrievalStatus: 27,
                ChangeContact: 28,
				CreatePreviewAppealApplication: 29,
				AddPrepareApplicationTasks: 30,
				PPReturnDataLoaded: 31,
				// PPReturnReturnsReviewed: 32,
				// PPReturnReturnsApproved: 33
                MarkInvoiceUBR: 34,
                ChangeConfirmHearingDate: 35,
				AddObtainPaymentReceiptTasks: 36,
				CompleteObtainPaymentReceiptTasks: 37,
                PreviewThenTransferUBR: 38,
                TransferUBR: 39,
                RequestReliefOfUBR: 40,
                InsertObtainWorkpapersTaskBefore: 41,
                GenerateAppealListReport: 42,
				AppealRecommendation: 43,
				AddToPaymentBatch: 44,
				PerformOcr: 45
            }
        )
        .constant('ReassignModes', {
            Default: 1,
            ChangeContact: 2
        })
        .constant('ContentTypes', {
            KNOWNISSUES: 1,
            RELEASENOTES: 2
		})
		.constant('SavedSearchCategoryEnum', {
			ComplianceReportSetup: 200,
			ComplianceReturnReport: 201,
			CompliancePackageReport: 202,
			ComplianceCustomReport: 203
		})
		.constant('RyanInstanceId', 1)
		.constant('ContactModalOrigin', {
			ViewProfile:1,
			ContactsList:2,
			EntityContact:3,
			NewContact:4
		})
		.constant('ActivityStatusIdMap', {
			0: 'Inactive',
			1: 'ActivePending',
			2: 'Active'
		})
})();
