import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AgGridExtensionsModule } from '../../Compliance/AgGrid/agGridExtensions.module';
import { AgGridModule } from 'ag-grid-angular';
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { ValuationModule } from '../../Valuation/valuation.module';
import { AddParcelModalComponent } from './Parcels/Add-Parcel-Modal/addParcelModal.component';
import { FMVChangeRendererComponent } from './Parcels/agGridFMVChangeRenderer.component';
import { ParcelListPanelComponent } from './Parcels/parcelListPanel.component';
import { ParcelTileComponent } from './Parcels/Parcel-Tile/parcelTile.component';
import { PercentChangeRendererComponent } from './Parcels/agGridPercentChangeRenderer.component';
import { TotalFMVRendererComponent } from './Parcels/agGridFMVRenderer.component';
import { SiteService } from './Site.Service.upgrade';
import { SiteInfoPanelComponent } from './Info/siteInfoPanel.component';
import { ComplianceSiteModule } from '../../Compliance/Site/site.module';
import { ParcelBulkUpdateComponent } from './Parcels/Parcel-Bulk-Update/parcelBulkUpdate.component';
import { ParcelBulkUpdateActionComponent } from './Parcels/Parcel-Bulk-Update/Parcel-Bulk-Update-Action/parcelBulkUpdateAction.component';
import { ParcelListActionCellRendererComponent } from './Parcels/agGridActionCellRenderer.component';
import { ParcelIconModule } from '../Parcel/Icon/parcelIcon.module';
import { AssetListPanelComponent } from './Assets/assetListPanel.component';
import { ValuationPanelComponent } from './Valuation/valuationPanel.component';
import { AssetModule } from '../../Compliance/Asset/asset.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ResponsibleEntityPanelComponent } from './Responsible-Entity/responsibleEntityPanel.component';
import { ResponsibleEntityModule } from '../../Responsible-Entity/responsibleEntity.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        WeissmanSharedModule,
        TypeaheadModule,
        AgGridExtensionsModule,
        AgGridModule,
        ComplianceSiteModule,
        ParcelIconModule,
        BusyIndicatorModule.forRoot(),
        AssetModule,
        TabsModule,
        ValuationModule,
        ResponsibleEntityModule
    ],
    declarations: [
        AddParcelModalComponent,
        FMVChangeRendererComponent,
        ParcelListPanelComponent,
        ParcelListActionCellRendererComponent,
        ParcelTileComponent,
        ParcelBulkUpdateComponent,
        ParcelBulkUpdateActionComponent,
        PercentChangeRendererComponent,
        TotalFMVRendererComponent,
        SiteInfoPanelComponent,
        AssetListPanelComponent,
        ValuationPanelComponent,
        ResponsibleEntityPanelComponent
    ],
    providers: [
        SiteService
    ],
    exports: [
        ParcelListPanelComponent
    ]
})
export class SiteModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Provider('sd.Site.Service', SiteService);
        hybridAdapterUtility.downgradeNg2Component('siteInfoPanel', SiteInfoPanelComponent);
        hybridAdapterUtility.downgradeNg2Component('parcelListPanel', ParcelListPanelComponent);
        hybridAdapterUtility.downgradeNg2Component('assetListPanel', AssetListPanelComponent);
        hybridAdapterUtility.downgradeNg2Component('valuationPanel', ValuationPanelComponent);
        hybridAdapterUtility.downgradeNg2Component('responsibleEntityPanel', ResponsibleEntityPanelComponent);
    }
}
