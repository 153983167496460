import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';
import { CompanyModel, CompanyService } from '../company.service';
import * as _ from 'lodash';
import { Address } from 'src/app/Common/Models/common.model';
import {
    AddressDetailsModalLaunchService
} from 'src/app/Common/Address/Address-View-Edit/address.details.modal.launch.service';
import { EntityTypeIds } from 'src/app/constants.new';
import { EntityAddress } from 'src/app/Common/Address/Address-View-Edit/address.model';
import { InstanceRights, RestrictData, RestrictService } from 'src/app/Common/Permissions/restrict.service';
import { CommentModalData } from 'src/app/Comments/comments.service';
import { AttachmentModalData } from 'src/app/Attachment/attachment.modal.model';
import { ParentCompanyModel } from './companyInfoPanel.component';
import { HelpService } from 'src/app/UI-Lib/Help-Tooltip';
import { COMPANY_INFO_PANEL_HELP } from './companyInfoPanel.component.help';
import { FeatureFlagsService } from '../../../Common/FeatureFlags/feature-flags-service';
import { UpgradeNavigationServiceHandler } from 'src/app/Common/Routing/upgrade-navigation-handler.service';
import { UserInstanceService } from 'src/app/User/userInstance.service';

@Component({
    selector: 'company-info-panel-view',
    templateUrl: 'companyInfoPanelView.component.html',
    styleUrls: ['companyInfoPanelView.component.scss']
})

export class CompanyInfoPanelViewComponent implements OnInit, OnChanges {
    constructor(private readonly _addressDetailsModalLaunchService: AddressDetailsModalLaunchService,
                private readonly _restrictService: RestrictService,
                private readonly _helpService: HelpService,
                private readonly _companyService: CompanyService,
                private readonly _routerService: UpgradeNavigationServiceHandler,
                private readonly _featureFlagsService: FeatureFlagsService,
                private readonly _userInstanceService: UserInstanceService,
                private readonly _productAnalyticsService: ProductAnalyticsService) {
        this._helpService.setContent(COMPANY_INFO_PANEL_HELP);
    }

    @Input() company: CompanyModel;
    @Input() defaultAddress: Address;
    @Input() parentCompany: ParentCompanyModel;
    @Input() activatedByPopover: TemplateRef<any>;
    @Output() serverActionChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    addressPopoverOpened: boolean = false;
    numInactiveSubsidiaries: number = 0;
    subsidiaries: Weissman.Model.Domain.SubsidiaryCompanyDTO[];
    subHeader: string;
    showInactiveSubsidiaries: boolean = false;
    hasInstancePrivateViewPermission: boolean = false;
    hasInstancePrivateEditPermission: boolean = false;
    accrualsEnabled: boolean = false;
    hasWritePermission: boolean = false;
    isFavoriting: boolean = false;
    isIAMEnabled: boolean = false;
    analyticsCategory: string = 'company';

    get companyIsActive(): boolean {
        return this.company.activityStatusID === Core.ActivityStatuses.Active;
    }

    get inactiveUrl(): string {
        return this.company.activityStatusID === Core.ActivityStatuses.Inactive ? '/images/inactive.jpg' : '/images/active-pending.jpg';
    }

    get isTopLevel(): boolean {
        return !this.company.parentId;
    }

    get attachmentsModel(): AttachmentModalData {
        if (!this.company) {
            return null;
        }

        return {
            entityType: 'Company',
            entityName: this.company.companyName,
            entityData: {
                typeId: Core.EntityTypes.Company,
                id: this.company.companyID,
                name: this.company.companyName
            },
            readOnly: !this.hasWritePermission
        } as AttachmentModalData;
    }

    get commentsModel(): CommentModalData {
        if (!this.company) {
            return null;
        }

        return {
            entityID: this.company.companyID,
            entityTypeID: Core.EntityTypes.Company,
            description: `Company: ${this.company.companyName}`,
            canEdit: this.hasWritePermission
        } as CommentModalData;
    }

    get isCompanyInRyanInstance(): boolean {
        return this.company.instanceID == this._userInstanceService.RyanInstanceId;
    }

    get isRyanInsanceSelected(): boolean {
        return this._userInstanceService.isCurrentInstanceRyan();
    }

    get shouldDisplayPaymentBatches(): boolean {
        return this._featureFlagsService.featureFlags.enablePaymentBatch
            && this.isTopLevel
            && this.isCompanyInRyanInstance
            && this.isRyanInsanceSelected
            && this.hasInstancePrivateEditPermission
            && this.hasWritePermission;
    }

    async ngOnInit(): Promise<void> {
        this.isIAMEnabled = this._featureFlagsService.featureFlags.enableIAM;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.company?.currentValue) {
            this.numInactiveSubsidiaries = this.company.childCompanies.length - this.company.childCompaniesNotInactive.length;
            this._setSubHeader();
            this._setSubsidiaries();

            if (changes.company.firstChange) {
                this.hasInstancePrivateViewPermission = this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT, this.company.instanceID)
                    || this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSVIEW, this.company.instanceID);

                this.hasInstancePrivateEditPermission = this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT, this.company.instanceID);

                this.serverActionChange.emit(true);
                try {
                    const restrictionData = {
                        isCompany: true,
                        entityId: this.company.companyID,
                        roles: [],
                        flag: Core.AccessRightsEnum.Write
                    } as RestrictData;

                    const dataPromises = [
                        this._companyService.checkAccrualsEnabled(),
                        this._restrictService.hasPermission(restrictionData)];

                    [this.accrualsEnabled, this.hasWritePermission] = await Promise.all(dataPromises);
                } finally {
                    this.serverActionChange.emit(false);
                }
            }
        }
    }

    launchAddressModal(): void {
        this._addressDetailsModalLaunchService.openAddressDetailsModal(
            this.company.companyID,
            EntityTypeIds.COMPANY,
            _.cloneDeep(this.company.entityAddresses) as unknown as EntityAddress[],
            false
        );
    }

    showInactiveSubsidiariesClicked(): void {
        this.showInactiveSubsidiaries = true;
        this._setSubsidiaries();
    }

    analyticsEvent(event: string): void {
        this._productAnalyticsService.logEvent(event);
    }

    async toggleFavorite(): Promise<void> {
        this.isFavoriting = true;

        try {
            await this._companyService.setIsCompanyFavorite(this.company.companyID, !this.company.isFavorite);
            this.company.isFavorite = !this.company.isFavorite;
        } finally {
            this.isFavoriting = false;
        }
    }

    goToPBCC(): void {
        sessionStorage['fromTlCompany'] = this.company.companyName;
        sessionStorage['pbccFilters'] = JSON.stringify({ tlCompanyIds: [this.company.topLevelCompanyId] });
        this._routerService.go('paymentBatchCommandCenter', null);
    }

    private _setSubHeader(): void {
        let subHeader = this.company.parentId ? 'Subsidiary; ' : 'Top Level; ';

        if (this.company.siteCount > 0) {
            subHeader += `${this.company.siteCount} site`;
            if (this.company.siteCount > 1) {
                subHeader += 's';
            }

            if (this.company.subCompSiteCount) {
                subHeader += ' plus ';
            }
        }
        if (this.company.subCompSiteCount) {
            subHeader += `${this.company.subCompSiteCount} subsidiary site`;
            if (this.company.subCompSiteCount > 1) {
                subHeader += 's';
            }
        }
        if (!this.company.siteCount && !this.company.subCompSiteCount) {
            subHeader += '0 sites';
        }

        this.subHeader = subHeader;
    }

    private _setSubsidiaries(): void {
        if (this.numInactiveSubsidiaries > 0 && !this.showInactiveSubsidiaries) {
            this.subsidiaries = this.company.childCompaniesNotInactive;
        } else {
            this.subsidiaries = this.company.childCompanies;
        }
    }
}
