import { State } from '../../Common/States/state.model';

declare const _: any;

export class Appeal {
    annualAssessmentID: number;
    appealID: number;
    appealLevel: AppealLevel;
    appealLevelID: number;
    appealStatusID: number;
    outcomeAnnualAssessmentID: number;
    fileByDate: string;
    submitEvidenceDateTimeLocal: string;
    hearingDateTimeLocal: string;
    confirmHearingDate: string;
    informalHearingDateTimeLocal: string;
    savingsDate: string;
    anticipatedFMV: number;
    savings: number;
    savingsOverridden: boolean;
    caseNumber: string;
    filingReference: string;
    hearingLocation: string;
    hasComments: boolean;
    hasAttachments: boolean;
    rowVersion: any;
    additionalNotificationMessage: boolean;
    hasApplicationForm: boolean;
    canCreateApplicationForm: boolean;
    isFromAcquisition: boolean;
    isRyanLegalServices: boolean;
}

// Consider using Core.AppealLevelModel autogenerated from backend
// Consider moving this to another file if it ends up referenced in many places or this file ends up very large
export class AppealLevel {
    abbr: string;
    appealLevelID: number;
    efAction: string;
    fullName: string;
    sequence: number;
    stateID: number;
    state: State;
    litigation: boolean;
}

export class AppealStatus {
    constructor(
        public appealStatusID: number,
        public description: string,
        public isClosedStatus: boolean,
        public status: string,
        public sequence: number) { }

    static appealStatuses: AppealStatus[];
    static getByID(appealStatusID: number): AppealStatus {
        return _.find(AppealStatus.appealStatuses, (appealStatus) => {
            return appealStatus.appealStatusID === appealStatusID;
        });
    }
}

export enum AppealStatusID {
    //ToBeFiled = 1,
    //Open = 2,
    //Board = 3,
    //Continued = 4,
    //Pending = 5,
    //Litigation = 6,
	Null = null,
    Withdrawn = 7,
    //STIP = 8, Removed by Grant request
    Closed = 9,
    AddInError = 10,
    Reduction = 11
}

// If this was C#, I'd use a static constructor for this. There is no such thing, so this works.
// https://github.com/Microsoft/TypeScript/issues/265

AppealStatus.appealStatuses = [
    //new AppealStatus(1, 'Identified for appeal but appeal application has yet to be filed.', false, 'To Be Filed', 1),
    //new AppealStatus(2, 'Default setting.  Open appeal; waiting for hearing date to be assigned.  Typically the status is Open until changed due to the progression of the hearing.', false, 'Open', 2),
    //new AppealStatus(3, 'If an appeal has transitioned to the Appeal Board and is waiting for further action, the appeal status should be changed to Board. ', false, 'Board', 3),
    //new AppealStatus(4, 'Continued or rescheduled appeal.  Once the date and time are changed on an existing appeal, the status must be changed to �Continued�.  Confirmation from the Appeals Board should be received before AtlasTax is updated with this status.', false, 'Continued', 4),
    //new AppealStatus(5, 'Waiting for hearing results or stipulated value confirmation from the Appeals Board.', false, 'Pending', 5),
    //new AppealStatus(6, 'This status is specially assigned to hearings that go into a �Litigation� process, and should relate to the notice received.', false, 'Litigation', 6),
	new AppealStatus(null, '', true, '', null),
    new AppealStatus(7, 'Withdrawn appeal; no further action will be taken. A completion date MUST be entered.', true, 'Withdrawn', 7),
    //new AppealStatus(8, 'Stipulated Value; representing an agreement with the Assessor; was obtained and a reduction in value is confirmed.  A completion date should be entered.', true, 'STIP', 8), Removed by Grant request
    new AppealStatus(9, 'If an appeal is Closed, possibly due to negotiations with the Assessor, but not withdrawn.  A completion date MUST be entered.', true, 'Closed', 9),
    new AppealStatus(10, 'If an appeal has been added erroneously the status needs to be changed to �Added in Error�.  A completion date must be entered.', true, 'Add-in-Error', 10),
    new AppealStatus(11, 'Reduction achieved resulting in savings.', true, 'Reduction', 11)
];

// (I generated this code from an API call in Weissman; if we change the statuses, the code can be regenerated using this JavaScript executed in a console from Weissman)
/*
(function () {
    "use strict";
    var authData = JSON.parse(sessionStorage["ngStorage-authorizationData"])["token"];
    $.ajax({
        type: "GET",
        cache: false,
        url: "/api/appealstatus",
        headers: {
            Authorization: "Bearer " + authData
        }
    }).done(function (result) {
        console.log(_.map(result, function (appealStatus) {
            return "new AppealStatus(" + [
                appealStatus.appealStatusID,
                "'" + appealStatus.description + "'",
                appealStatus.isClosedStatus,
                "'" + appealStatus.status + "'",
                appealStatus.sequence].join(", ") + "),";
        }).join("\n"));
    });
}());
*/
