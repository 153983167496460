import { NgModule } from '@angular/core';
import { CreatePaymentBatchModalComponent } from './Create-Payment-Batch-Modal/createPaymentBatchModal.component';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { CompanyModule } from '../Entity/Company/company.module';
import { BusyIndicatorModule } from '../Busy-Indicator';
import { AgGridExtensionsModule } from '../Compliance/AgGrid/agGridExtensions.module';
import { AgGridModule } from 'ag-grid-angular';
import { PaymentBatchListGridActionCellRendererComponent } from './Command-Center/agGridActionCellRenderer.component';
import { PaymentBatchCommandCenterComponent } from './Command-Center/paymentBatch.component';
import { PaymentBatchService } from './paymentBatch.service';
import { PaymentBatchDetailsComponent } from './Payment-Batch-Details/paymentBatchDetails.component';
import { PaymentBatchPaymentsGridComponent } from './Payment-Batch-Details/Payments-Grid/paymentBatchPaymentsGrid.component';
import { PaymentBatchPaymentsGridActionCellRendererComponent } from './Payment-Batch-Details/Payments-Grid/agGridActionCellRenderer.component';
import { TransmittalModule } from '../Processing/Transmittal/transmittal.module';
import { PaymentBatchAddPaymentsModalComponent } from './Payment-Batch-Details/Add-Payments-Modal/addPaymentsToBatchModal.component';
import { PaymentBatchRepository } from '../Core-Repositories/paymentBatch.repository';
import { AddPaymentsToBatchModalComponent } from './Add-Payments-To-Batch-Modal/addPaymentsToBatchModal.component';
import { AddPaymentsToBatchModalService } from './Add-Payments-To-Batch-Modal/addPaymentsToBatchModal.service';
import { PaymentBatchTaskExceptionModalComponent } from './Payment-Batch-Details/Task-Exception-Modal/paymentBatchTaskExceptionModal.component';
import { PaymentBatchPackagesComponent } from './Payment-Batch-Details/Packages-Table/paymentBatchPackages.component';
import { PaymentBatchOutputComponent } from './Payment-Batch-Details/Output/paymentBatchOutput.component';
import { PaymentBatchPaymentsGridPaymentErrorsCellRendererComponent } from './Payment-Batch-Details/Payments-Grid/agGridPaymentErrorsCellRenderer.component';
import { PaymentBatchSettingsComponent } from './Payment-Batch-Details/Settings/paymentBatchSettings.component';
import { CheckImportModalComponent } from './Payment-Batch-Details/Check-Import-Modal/checkImportModal.component';
import { FinalizeCheckImportPaymentGridComponent } from './Payment-Batch-Details/Check-Import-Modal/Finalize/finalizeCheckImportPaymentGrid.componet';
import { FinalizeCheckImportChecksGridComponent } from './Payment-Batch-Details/Check-Import-Modal/Finalize/finalizeCheckImportChecksGrid.component';
import { FinalizeCheckImportModalComponent } from './Payment-Batch-Details/Check-Import-Modal/Finalize/finalizeCheckImportModal.component';
import { PaymentBatchSummaryComponent } from './Payment-Batch-Details/Payment-Batch-Summary/paymentBatchSummary.component';
import { PaymentBatchFiltersComponent } from './Command-Center/Filters/paymentBatchFilters.component';

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        WeissmanSharedModule,
        CompanyModule,
        AgGridExtensionsModule,
        AgGridModule.withComponents([
            PaymentBatchListGridActionCellRendererComponent,
            PaymentBatchPaymentsGridActionCellRendererComponent,
            PaymentBatchPaymentsGridPaymentErrorsCellRendererComponent
        ]),
        BusyIndicatorModule.forRoot(),
        TransmittalModule
    ],
    declarations: [
        CreatePaymentBatchModalComponent,
        PaymentBatchCommandCenterComponent,
        PaymentBatchListGridActionCellRendererComponent,
        PaymentBatchDetailsComponent,
        PaymentBatchPaymentsGridActionCellRendererComponent,
        PaymentBatchPaymentsGridComponent,
        PaymentBatchAddPaymentsModalComponent,
        AddPaymentsToBatchModalComponent,
        PaymentBatchTaskExceptionModalComponent,
        PaymentBatchPackagesComponent,
        PaymentBatchOutputComponent,
        PaymentBatchPaymentsGridPaymentErrorsCellRendererComponent,
        PaymentBatchSettingsComponent,
        CheckImportModalComponent,
        FinalizeCheckImportPaymentGridComponent,
        FinalizeCheckImportChecksGridComponent,
        FinalizeCheckImportModalComponent,
        PaymentBatchSummaryComponent,
        PaymentBatchFiltersComponent
    ],
    providers: [
        PaymentBatchService,
        PaymentBatchRepository,
        AddPaymentsToBatchModalService
    ],
    exports: []
})
export class PaymentBatchModule {
    static setupModule(): void {
        hybridAdapterUtility.downgradeNg2Component('paymentBatchCommandCenter', PaymentBatchCommandCenterComponent);
        hybridAdapterUtility.downgradeNg2Component('paymentBatchDetails', PaymentBatchDetailsComponent);
        hybridAdapterUtility.downgradeNg2Provider('addPaymentsToBatchModalService', AddPaymentsToBatchModalService);
    }
}