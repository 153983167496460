<div class="return-settings">
    <form #form="ngForm" *ngIf="settings">
        <div class="mb-3 row">
            <div class="col-md-1 row-header">Show</div>
            <div class="col-md-3">
                <input type="radio" name="viewMode" [value]="ViewModelEnum.ReturnValues" [(ngModel)]="viewMode" [disabled]="editMode" id="viewModeReturnValues">
                <label for="viewModeReturnValues" helpTooltip helpContentId="return-info.return-values">&nbsp;Return Values</label>
                <input type="radio" name="viewMode" [value]="ViewModelEnum.Settings" [(ngModel)]="viewMode" [disabled]="editMode" id="viewModeSettings">
                <label for="viewModeSettings" helpTooltip helpContentId="return-info.settings">&nbsp;Settings</label>
            </div>
        </div>
        <div class="mb-5 row">
            <div class="col-md-2 row-header" [ngClass]="{'red': redWetSignatureOverrideLabelText}">
                <span>
                    Wet Signature Override
                    <i class="fa fa-info-circle ms-2"
                       helpTooltip
                       helpContentId="return-info.allow-wet-signature-override"></i>
                </span>
            </div>
            <div class="col-md-1">
                <ws-switch [(ngModel)]="settings.currentGeneralReturnSetting.allowWetSignatureOverride"
                           name="wetSignatureOverrideAllowed"
                           [disabled]="!editMode"
                           (selectedOptionChanged)="hasChanges = true;"
                           size="small">
                </ws-switch>
            </div>
            <div class="col-md-2 row-header" *ngIf="!hideWetSignatureOverrideWarning">
                (Various between returns)
            </div>
        </div>
        <div class="mb-3 row" *ngIf="settings.scAlternateLienDates.length > 0">
            <div class="col-md-2 row-header">
                <span>
                    SC Alternative Lien Date
                    <i class="fa fa-info-circle ms-2"
                       helpTooltip
                       helpContentId="return-info.sc-alt-lien-date">
                    </i>
                </span>
            </div>
            <div class="col-md-1">
                <select name="scAltLienDate"
                        class="width: auto;"
                        [(ngModel)]="settings.currentGeneralReturnSetting.scAlternateLienDateId"
                        [disabled]="!editMode || !settings.isTopLevelCompany"
                        (ngModelChange)="settings.currentGeneralReturnSetting.scAlternateLienDateId = $event; hasChanges = true;">
                    <option *ngFor="let scald of settings.scAlternateLienDates" [ngValue]="scald.value">
                        {{ scald.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="mb-3 row">
            <div class="col-md-1 column-header">Owner</div>
            <div class="col-md-2 column-header">Name</div>
            <div class="col-md-5 column-header">Mailing Address</div>
            <div class="col-md-3 column-header">&nbsp;</div>
        </div>
        <div class="mb-3 row">
            <div class="col-md-1 row-header" helpTooltip helpContentId="return-info.legal-entity">Legal Entity</div>
            <div class="col-md-2">
                <return-settings-name-attribute [lookupOptions]="getLookupOptions(LookupTypeEnum.EntityName)" [isTopLevelCompany]="settings.isTopLevelCompany"
                                                [currentLookupId]="settings.currentGeneralReturnSetting.legalEntityNameLookupId"
                                                [parentLookupId]="settings.parentGeneralReturnSetting.legalEntityNameLookupId"
                                                [isReadonly]="!editMode"
                                                [viewMode]="viewMode"
                                                [currentValue]="settings.currentGeneralReturnSetting.legalEntityNameLookupValue"
                                                [parentValue]="settings.parentGeneralReturnSetting.legalEntityNameLookupValue"
                                                [lookupType]="ReturnSettingsLookupType.Name"
                                                [settingType]="ReturnSettingsSettingTypeEnum.Entity"
                                                (changed)="this.settings.currentGeneralReturnSetting.legalEntityNameLookupId = $event; hasChanges = true;"
                                                [entityType]="entityType"></return-settings-name-attribute>
            </div>
            <div class="col-md-5">
                <return-settings-entity-attribute [lookupOptions]="getLookupOptions(LookupTypeEnum.EntityAddress)" [isTopLevelCompany]="settings.isTopLevelCompany"
                                                  [currentLookupId]="settings.currentGeneralReturnSetting.legalEntityAddressLookupId"
                                                  [parentLookupId]="settings.parentGeneralReturnSetting.legalEntityAddressLookupId"
                                                  [isReadonly]="!editMode"
                                                  [viewMode]="viewMode"
                                                  [currentValue]="settings.currentGeneralReturnSetting.legalEntityAddressLookupValue"
                                                  [parentValue]="settings.parentGeneralReturnSetting.legalEntityAddressLookupValue"
                                                  [currentSettings]="settings.currentGeneralReturnSetting"
                                                  [parentSettings]="settings.parentGeneralReturnSetting"
                                                  [lookupType]="ReturnSettingsLookupType.Address"
                                                  [settingType]="ReturnSettingsSettingTypeEnum.Entity"
                                                  (changed)="this.settings.currentGeneralReturnSetting.legalEntityAddressLookupId = $event; hasChanges = true;"
                                                  [entityType]="entityType"></return-settings-entity-attribute>
            </div>
            <div class="col-md-3"></div>
        </div>
        <div class="mb-3 row">
            <div class="col-md-1 row-header" helpTooltip helpContentId="return-info.business-dba">Business/DBA</div>
            <div class="col-md-2">
                <return-settings-name-attribute [lookupOptions]="getLookupOptions(LookupTypeEnum.EntityName)" [isTopLevelCompany]="settings.isTopLevelCompany"
                                                [currentLookupId]="settings.currentGeneralReturnSetting.businessNameLookupId"
                                                [parentLookupId]="settings.parentGeneralReturnSetting.businessNameLookupId"
                                                [isReadonly]="!editMode"
                                                [viewMode]="viewMode"
                                                [currentValue]="settings.currentGeneralReturnSetting.businessNameLookupValue"
                                                [parentValue]="settings.parentGeneralReturnSetting.businessNameLookupValue"
                                                [lookupType]="ReturnSettingsLookupType.Name"
                                                [settingType]="ReturnSettingsSettingTypeEnum.Entity"
                                                (changed)="this.settings.currentGeneralReturnSetting.businessNameLookupId = $event; hasChanges = true;"
                                                [entityType]="entityType"></return-settings-name-attribute>
            </div>
            <div class="col-md-5">
                <return-settings-entity-attribute [lookupOptions]="getLookupOptions(LookupTypeEnum.EntityAddress)" [isTopLevelCompany]="settings.isTopLevelCompany"
                                                  [currentLookupId]="settings.currentGeneralReturnSetting.businessAddressLookupId"
                                                  [parentLookupId]="settings.parentGeneralReturnSetting.businessAddressLookupId"
                                                  [isReadonly]="!editMode"
                                                  [viewMode]="viewMode"
                                                  [currentValue]="settings.currentGeneralReturnSetting.businessAddressLookupValue"
                                                  [parentValue]="settings.parentGeneralReturnSetting.businessAddressLookupValue"
                                                  [currentSettings]="settings.currentGeneralReturnSetting"
                                                  [parentSettings]="settings.parentGeneralReturnSetting"
                                                  [lookupType]="ReturnSettingsLookupType.Address"
                                                  [settingType]="ReturnSettingsSettingTypeEnum.Entity"
                                                  (changed)="this.settings.currentGeneralReturnSetting.businessAddressLookupId = $event; hasChanges = true;"
                                                  [entityType]="entityType"></return-settings-entity-attribute>
            </div>
            <div class="col-md-3"></div>
        </div>
        <div class="mb-3 row">
            <div class="col-md-1 row-header" helpTooltip helpContentId="return-info.taxpayer">Taxpayer</div>
            <div class="col-md-2">
                <return-settings-name-attribute [lookupOptions]="getLookupOptions(LookupTypeEnum.EntityName)" [isTopLevelCompany]="settings.isTopLevelCompany"
                                                [currentLookupId]="settings.currentGeneralReturnSetting.taxpayerNameLookupId"
                                                [parentLookupId]="settings.parentGeneralReturnSetting.taxpayerNameLookupId"
                                                [isReadonly]="!editMode"
                                                [viewMode]="viewMode"
                                                [currentValue]="settings.currentGeneralReturnSetting.taxpayerNameLookupValue"
                                                [parentValue]="settings.parentGeneralReturnSetting.taxpayerNameLookupValue"
                                                [lookupType]="ReturnSettingsLookupType.Name"
                                                [settingType]="ReturnSettingsSettingTypeEnum.Entity"
                                                (changed)="this.settings.currentGeneralReturnSetting.taxpayerNameLookupId = $event; hasChanges = true;"
                                                [entityType]="entityType"></return-settings-name-attribute>
            </div>
            <div class="col-md-5">
                <return-settings-entity-attribute [lookupOptions]="getLookupOptions(LookupTypeEnum.EntityAddress)" [isTopLevelCompany]="settings.isTopLevelCompany"
                                                  [currentLookupId]="settings.currentGeneralReturnSetting.taxpayerAddressLookupId"
                                                  [parentLookupId]="settings.parentGeneralReturnSetting.taxpayerAddressLookupId"
                                                  [isReadonly]="!editMode"
                                                  [viewMode]="viewMode"
                                                  [currentValue]="settings.currentGeneralReturnSetting.taxpayerAddressLookupValue"
                                                  [parentValue]="settings.parentGeneralReturnSetting.taxpayerAddressLookupValue"
                                                  [currentSettings]="settings.currentGeneralReturnSetting"
                                                  [parentSettings]="settings.parentGeneralReturnSetting"
                                                  [lookupType]="ReturnSettingsLookupType.Address"
                                                  [settingType]="ReturnSettingsSettingTypeEnum.Entity"
                                                  (changed)="this.settings.currentGeneralReturnSetting.taxpayerAddressLookupId = $event; hasChanges = true;"
                                                  [entityType]="entityType"></return-settings-entity-attribute>
            </div>
            <div class="col-md-3"></div>
        </div>
        <br />
        <div class="mb-3 row">
            <div class="col-md-1 column-header">Contact</div>
            <div class="col-md-2 column-header">Name</div>
            <div class="col-md-5 column-header">Mailing Address</div>
            <div class="col-md-3 column-header">Phone / Fax / Email</div>
        </div>
        <div class="mb-3 row">
            <div class="col-md-1 row-header" helpTooltip helpContentId="return-info.owner">Owner</div>
            <div class="col-md-2">
                <return-settings-contact-attribute [isTopLevelCompany]="settings.isTopLevelCompany"
                                                   [currentContact]="settings.currentGeneralReturnSetting.ownerContact"
                                                   [parentContact]="settings.parentGeneralReturnSetting.ownerContact"
                                                   [parentContactNameLookupValue]="settings.parentGeneralReturnSetting.ownerContactNameLookupValue"
                                                   [currentRepresentationId]="settings.currentGeneralReturnSetting.ownerContactRepresentationTypeId"
                                                   [parentRepresentationId]="settings.parentGeneralReturnSetting.ownerContactRepresentationTypeId"
                                                   [currentRepresentationValue]="settings.currentGeneralReturnSetting.ownerContactRepresentationTypeName"
                                                   [parentRepresentationValue]="settings.parentGeneralReturnSetting.ownerContactRepresentationTypeName"
                                                   [isReadonly]="!editMode"
                                                   [viewMode]="viewMode"
                                                   (contactChanged)="ownerContact = $event"
                                                   (representationChanged)="this.settings.currentGeneralReturnSetting.ownerContactRepresentationTypeId = $event; hasChanges = true;"
                                                   [currentContactId]="settings.currentGeneralReturnSetting.ownerContactId"
                                                   [parentContactId]="settings.parentGeneralReturnSetting.ownerContactId"
                                                   [entityType]="entityType"
                                                   [entityId]="entityId">
                </return-settings-contact-attribute>
            </div>
            <div class="col-md-5">
                <return-settings-entity-attribute [lookupOptions]="getLookupOptions(LookupTypeEnum.ContactAddress)" [isTopLevelCompany]="settings.isTopLevelCompany"
                                                  [currentLookupId]="settings.currentGeneralReturnSetting.ownerContactAddressLookupId"
                                                  [parentLookupId]="settings.parentGeneralReturnSetting.ownerContactAddressLookupId"
                                                  [isReadonly]="!editMode"
                                                  [viewMode]="viewMode"
                                                  [currentValue]="settings.currentGeneralReturnSetting.ownerContactAddressLookupValue"
                                                  [parentValue]="settings.parentGeneralReturnSetting.ownerContactAddressLookupValue"
                                                  [currentSettings]="settings.currentGeneralReturnSetting"
                                                  [parentSettings]="settings.parentGeneralReturnSetting"
                                                  [lookupType]="ReturnSettingsLookupType.Address"
                                                  [settingType]="ReturnSettingsSettingTypeEnum.OwnerContact"
                                                  (changed)="this.settings.currentGeneralReturnSetting.ownerContactAddressLookupId = $event; hasChanges = true;"
                                                  [entityType]="entityType" [contactName]="ownerContactName"
                                                  [optionSettingNameId]="GeneralReturnSettingLookupEnum.ContactAddressSettingName"
                                                  [optionBlankId]="GeneralReturnSettingLookupEnum.ContactAddressBlank"></return-settings-entity-attribute>
            </div>
            <div class="col-md-3">
                <return-settings-entity-attribute [lookupOptions]="getLookupOptions(LookupTypeEnum.ContactPhone)" [isTopLevelCompany]="settings.isTopLevelCompany"
                                                  [currentLookupId]="settings.currentGeneralReturnSetting.ownerContactPhoneLookupId"
                                                  [parentLookupId]="settings.parentGeneralReturnSetting.ownerContactPhoneLookupId"
                                                  [isReadonly]="!editMode"
                                                  [viewMode]="viewMode"
                                                  [currentValue]="settings.currentGeneralReturnSetting.ownerContactPhoneLookupValue"
                                                  [parentValue]="settings.parentGeneralReturnSetting.ownerContactPhoneLookupValue"
                                                  [lookupType]="ReturnSettingsLookupType.Phone"
                                                  [settingType]="ReturnSettingsSettingTypeEnum.OwnerContact"
                                                  (changed)="this.settings.currentGeneralReturnSetting.ownerContactPhoneLookupId = $event; hasChanges = true;"
                                                  [entityType]="entityType" [contactName]="ownerContactName"
                                                  [optionSettingNameId]="GeneralReturnSettingLookupEnum.ContactPhoneSettingName"
                                                  [optionBlankId]="GeneralReturnSettingLookupEnum.ContactPhoneBlank"></return-settings-entity-attribute>
            </div>
        </div>
        <div class="mb-3 row">
            <div class="col-md-1 row-header" helpTooltip helpContentId="return-info.accounting">Accounting</div>
            <div class="col-md-2">
                <return-settings-contact-attribute [isTopLevelCompany]="settings.isTopLevelCompany"
                                                   [currentContact]="settings.currentGeneralReturnSetting.accountingContact"
                                                   [parentContact]="settings.parentGeneralReturnSetting.accountingContact"
                                                   [parentContactNameLookupValue]="settings.parentGeneralReturnSetting.accountingContactNameLookupValue"
                                                   [currentRepresentationId]="settings.currentGeneralReturnSetting.accountingContactRepresentationTypeId"
                                                   [parentRepresentationId]="settings.parentGeneralReturnSetting.accountingContactRepresentationTypeId"
                                                   [currentRepresentationValue]="settings.currentGeneralReturnSetting.accountingContactRepresentationTypeName"
                                                   [parentRepresentationValue]="settings.parentGeneralReturnSetting.accountingContactRepresentationTypeName"
                                                   [isReadonly]="!editMode"
                                                   [viewMode]="viewMode"
                                                   (contactChanged)="accountingContact = $event"
                                                   (representationChanged)="this.settings.currentGeneralReturnSetting.accountingContactRepresentationTypeId = $event; hasChanges = true;"
                                                   [currentContactId]="settings.currentGeneralReturnSetting.accountingContactId"
                                                   [parentContactId]="settings.parentGeneralReturnSetting.accountingContactId"
                                                   [entityType]="entityType"
                                                   [entityId]="entityId">
                </return-settings-contact-attribute>
            </div>
            <div class="col-md-5">
                <return-settings-entity-attribute [lookupOptions]="getLookupOptions(LookupTypeEnum.ContactAddress)" [isTopLevelCompany]="settings.isTopLevelCompany"
                                                  [currentLookupId]="settings.currentGeneralReturnSetting.accountingContactAddressLookupId"
                                                  [parentLookupId]="settings.parentGeneralReturnSetting.accountingContactAddressLookupId"
                                                  [isReadonly]="!editMode"
                                                  [viewMode]="viewMode"
                                                  [currentValue]="settings.currentGeneralReturnSetting.accountingContactAddressLookupValue"
                                                  [parentValue]="settings.parentGeneralReturnSetting.accountingContactAddressLookupValue"
                                                  [currentSettings]="settings.currentGeneralReturnSetting"
                                                  [parentSettings]="settings.parentGeneralReturnSetting"
                                                  [lookupType]="ReturnSettingsLookupType.Address"
                                                  [settingType]="ReturnSettingsSettingTypeEnum.AccountingContact"
                                                  (changed)="this.settings.currentGeneralReturnSetting.accountingContactAddressLookupId = $event; hasChanges = true;"
                                                  [entityType]="entityType" [contactName]="accountingContactName"
                                                  [optionSettingNameId]="GeneralReturnSettingLookupEnum.ContactAddressSettingName"
                                                  [optionBlankId]="GeneralReturnSettingLookupEnum.ContactAddressBlank"></return-settings-entity-attribute>
            </div>
            <div class="col-md-3">
                <return-settings-entity-attribute [lookupOptions]="getLookupOptions(LookupTypeEnum.ContactPhone)" [isTopLevelCompany]="settings.isTopLevelCompany"
                                                  [currentLookupId]="settings.currentGeneralReturnSetting.accountingContactPhoneLookupId"
                                                  [parentLookupId]="settings.parentGeneralReturnSetting.accountingContactPhoneLookupId"
                                                  [isReadonly]="!editMode"
                                                  [viewMode]="viewMode"
                                                  [currentValue]="settings.currentGeneralReturnSetting.accountingContactPhoneLookupValue"
                                                  [parentValue]="settings.parentGeneralReturnSetting.accountingContactPhoneLookupValue"
                                                  [lookupType]="ReturnSettingsLookupType.Phone"
                                                  [settingType]="ReturnSettingsSettingTypeEnum.AccountingContact"
                                                  (changed)="this.settings.currentGeneralReturnSetting.accountingContactPhoneLookupId = $event; hasChanges = true;"
                                                  [entityType]="entityType" [contactName]="accountingContactName"
                                                  [optionSettingNameId]="GeneralReturnSettingLookupEnum.ContactPhoneSettingName"
                                                  [optionBlankId]="GeneralReturnSettingLookupEnum.ContactPhoneBlank"></return-settings-entity-attribute>
            </div>
        </div>
        <div class="mb-3 row">
            <div class="col-md-1 row-header" helpTooltip helpContentId="return-info.preparer">Preparer</div>
            <div class="col-md-2">
                <return-settings-contact-attribute [isTopLevelCompany]="settings.isTopLevelCompany"
                                                   [currentContact]="settings.currentGeneralReturnSetting.preparerContact"
                                                   [parentContact]="settings.parentGeneralReturnSetting.preparerContact"
                                                   [parentContactNameLookupValue]="settings.parentGeneralReturnSetting.preparerContactNameLookupValue"
                                                   [currentRepresentationId]="settings.currentGeneralReturnSetting.preparerContactRepresentationTypeId"
                                                   [parentRepresentationId]="settings.parentGeneralReturnSetting.preparerContactRepresentationTypeId"
                                                   [currentRepresentationValue]="settings.currentGeneralReturnSetting.preparerContactRepresentationTypeName"
                                                   [parentRepresentationValue]="settings.parentGeneralReturnSetting.preparerContactRepresentationTypeName"
                                                   [isReadonly]="!editMode"
                                                   [viewMode]="viewMode"
                                                   (contactChanged)="preparerContact = $event"
                                                   (representationChanged)="this.settings.currentGeneralReturnSetting.preparerContactRepresentationTypeId = $event; hasChanges = true;"
                                                   [currentContactId]="settings.currentGeneralReturnSetting.preparerContactId"
                                                   [parentContactId]="settings.parentGeneralReturnSetting.preparerContactId"
                                                   [entityType]="entityType"
                                                   [entityId]="entityId">
                </return-settings-contact-attribute>
            </div>
            <div class="col-md-5">
                <return-settings-entity-attribute [lookupOptions]="getLookupOptions(LookupTypeEnum.ContactAddress)" [isTopLevelCompany]="settings.isTopLevelCompany"
                                                  [currentLookupId]="settings.currentGeneralReturnSetting.preparerContactAddressLookupId"
                                                  [parentLookupId]="settings.parentGeneralReturnSetting.preparerContactAddressLookupId"
                                                  [isReadonly]="!editMode"
                                                  [viewMode]="viewMode"
                                                  [currentValue]="settings.currentGeneralReturnSetting.preparerContactAddressLookupValue"
                                                  [parentValue]="settings.parentGeneralReturnSetting.preparerContactAddressLookupValue"
                                                  [currentSettings]="settings.currentGeneralReturnSetting"
                                                  [parentSettings]="settings.parentGeneralReturnSetting"
                                                  [lookupType]="ReturnSettingsLookupType.Address"
                                                  [settingType]="ReturnSettingsSettingTypeEnum.PreparerContact"
                                                  (changed)="this.settings.currentGeneralReturnSetting.preparerContactAddressLookupId = $event; hasChanges = true;"
                                                  [entityType]="entityType" [contactName]="preparerContactName"
                                                  [optionSettingNameId]="GeneralReturnSettingLookupEnum.ContactAddressSettingName"
                                                  [optionBlankId]="GeneralReturnSettingLookupEnum.ContactAddressBlank"></return-settings-entity-attribute>
            </div>
            <div class="col-md-3">
                <return-settings-entity-attribute [lookupOptions]="getLookupOptions(LookupTypeEnum.ContactPhone)" [isTopLevelCompany]="settings.isTopLevelCompany"
                                                  [currentLookupId]="settings.currentGeneralReturnSetting.preparerContactPhoneLookupId"
                                                  [parentLookupId]="settings.parentGeneralReturnSetting.preparerContactPhoneLookupId"
                                                  [isReadonly]="!editMode"
                                                  [viewMode]="viewMode"
                                                  [currentValue]="settings.currentGeneralReturnSetting.preparerContactPhoneLookupValue"
                                                  [parentValue]="settings.parentGeneralReturnSetting.preparerContactPhoneLookupValue"
                                                  [lookupType]="ReturnSettingsLookupType.Phone"
                                                  [settingType]="ReturnSettingsSettingTypeEnum.PreparerContact"
                                                  (changed)="this.settings.currentGeneralReturnSetting.preparerContactPhoneLookupId = $event; hasChanges = true;"
                                                  [entityType]="entityType" [contactName]="preparerContactName"
                                                  [optionSettingNameId]="GeneralReturnSettingLookupEnum.ContactPhoneSettingName"
                                                  [optionBlankId]="GeneralReturnSettingLookupEnum.ContactPhoneBlank"></return-settings-entity-attribute>
            </div>
        </div>
        <div class="mb-3 row">
            <div class="col-md-1 row-header" helpTooltip helpContentId="return-info.signer">Signer</div>
            <div class="col-md-2">
                <return-settings-contact-attribute [isTopLevelCompany]="settings.isTopLevelCompany"
                                                   [currentContact]="settings.currentGeneralReturnSetting.signerContact"
                                                   [parentContact]="settings.parentGeneralReturnSetting.signerContact"
                                                   [parentContactNameLookupValue]="settings.parentGeneralReturnSetting.signerContactNameLookupValue"
                                                   [currentRepresentationId]="settings.currentGeneralReturnSetting.signerContactRepresentationTypeId"
                                                   [parentRepresentationId]="settings.parentGeneralReturnSetting.signerContactRepresentationTypeId"
                                                   [currentRepresentationValue]="settings.currentGeneralReturnSetting.signerContactRepresentationTypeName"
                                                   [parentRepresentationValue]="settings.parentGeneralReturnSetting.signerContactRepresentationTypeName"
                                                   [isReadonly]="!editMode"
                                                   [viewMode]="viewMode"
                                                   (contactChanged)="signerContact = $event;"
                                                   (representationChanged)="this.settings.currentGeneralReturnSetting.signerContactRepresentationTypeId = $event; hasChanges = true;"
                                                   [currentContactId]="settings.currentGeneralReturnSetting.signerContactId"
                                                   [parentContactId]="settings.parentGeneralReturnSetting.signerContactId"
                                                   [entityType]="entityType"
                                                   [entityId]="entityId">
                </return-settings-contact-attribute>
            </div>
            <div class="col-md-5">
                <return-settings-entity-attribute [lookupOptions]="getLookupOptions(LookupTypeEnum.ContactAddress)" [isTopLevelCompany]="settings.isTopLevelCompany"
                                                  [currentLookupId]="settings.currentGeneralReturnSetting.signerContactAddressLookupId"
                                                  [parentLookupId]="settings.parentGeneralReturnSetting.signerContactAddressLookupId"
                                                  [isReadonly]="!editMode"
                                                  [viewMode]="viewMode"
                                                  [currentValue]="settings.currentGeneralReturnSetting.signerContactAddressLookupValue"
                                                  [parentValue]="settings.parentGeneralReturnSetting.signerContactAddressLookupValue"
                                                  [currentSettings]="settings.currentGeneralReturnSetting"
                                                  [parentSettings]="settings.parentGeneralReturnSetting"
                                                  [lookupType]="ReturnSettingsLookupType.Address"
                                                  [settingType]="ReturnSettingsSettingTypeEnum.SignerContact"
                                                  (changed)="this.settings.currentGeneralReturnSetting.signerContactAddressLookupId = $event; hasChanges = true;"
                                                  [entityType]="entityType" [contactName]="signerContactName"
                                                  [optionSettingNameId]="GeneralReturnSettingLookupEnum.ContactAddressSettingName"
                                                  [optionBlankId]="GeneralReturnSettingLookupEnum.ContactAddressBlank"></return-settings-entity-attribute>
            </div>
            <div class="col-md-3">
                <return-settings-entity-attribute [lookupOptions]="getLookupOptions(LookupTypeEnum.ContactPhone)" [isTopLevelCompany]="settings.isTopLevelCompany"
                                                  [currentLookupId]="settings.currentGeneralReturnSetting.signerContactPhoneLookupId"
                                                  [parentLookupId]="settings.parentGeneralReturnSetting.signerContactPhoneLookupId"
                                                  [isReadonly]="!editMode"
                                                  [viewMode]="viewMode"
                                                  [currentValue]="settings.currentGeneralReturnSetting.signerContactPhoneLookupValue"
                                                  [parentValue]="settings.parentGeneralReturnSetting.signerContactPhoneLookupValue"
                                                  [lookupType]="ReturnSettingsLookupType.Phone"
                                                  [settingType]="ReturnSettingsSettingTypeEnum.SignerContact"
                                                  (changed)="this.settings.currentGeneralReturnSetting.signerContactPhoneLookupId = $event; hasChanges = true;"
                                                  [entityType]="entityType" [contactName]="signerContactName"
                                                  [optionSettingNameId]="GeneralReturnSettingLookupEnum.ContactPhoneSettingName"
                                                  [optionBlankId]="GeneralReturnSettingLookupEnum.ContactPhoneBlank"></return-settings-entity-attribute>
            </div>
        </div>
    </form>
</div>
