<div *ngIf="filters" style="display: flex; gap: 50px; align-items: end; padding: 15px 0;">
    <div style="display: flex; flex-wrap: wrap; column-gap: 50px; row-gap: 25px;">
        <div class="first-block">
            <div class="field-block">
                <label>Top level companies:</label>
                <ws-multi-select 
                    style="min-width: 376px;"
                    searchType="substring"
                    [(ngModel)]="selections.tlCompanyIds"
                    (selectedOptionsChanged)="getSubsidiaries()"
                    [options]="filters?.tlCompanies ?? []"
                    labelProperty="name"
                    valueProperty="id"
                    placeholder="Search Top Level Companies..."
                    [canSearch]="true"
                    [hideSelectAll]="true">
                </ws-multi-select>
            </div>
            <div class="field-block">
                <label>Subsidiaries:</label>
                <ws-multi-select 
                    style="min-width: 376px;"
                    searchType="substring"
                    [disabled]="!availableSubCompanies.length"
                    [(ngModel)]="selections.subCompanyIds"
                    [options]="availableSubCompanies"
                    labelProperty="name"
                    valueProperty="id"
                    placeholder="Search Subsidiaries..."
                    [canSearch]="true"
                    (selectedOptionsChanged)="makeItDirty()"
                    [hideSelectAll]="true">
                </ws-multi-select>
            </div>
            <div class="field-block">
                <label>Date Type:</label>
                <ws-select  
                    placeholder="Select"
                    [(ngModel)]="selections.dateFilter.dateType"
                    [options]="dateTypeFilters"
                    labelProperty="name"
                    (selectedValueChanged)="dateTypeChanged()"
                    valueProperty="id">
                </ws-select>
                <div style="display: flex; gap: 10px; align-items: center;">
                    <weissman-datetime 
                        [(inputDate)]="selections.dateFilter.fromDate"
                        [dateOnly]="true"
                        [timeZone]="'Central'"
                        (inputDateChange)="makeItDirty()"
                        [isDisabled]="!selections.dateFilter.dateType">
                    </weissman-datetime>
                    to
                    <weissman-datetime 
                        [(inputDate)]="selections.dateFilter.toDate"
                        [dateOnly]="true"
                        [timeZone]="'Central'"
                        (inputDateChange)="makeItDirty()"
                        [rangeEnd]="true"
                        [isDisabled]="!selections.dateFilter.dateType">
                    </weissman-datetime>
                </div>
            </div>
        </div>
        <div class="second-block">
            <div class="field-block">
                <label>Batch Type:</label>
                <ws-select  
                    placeholder="Select"
                    [(ngModel)]="selections.batchTypeId"
                    [options]="filters?.batchTypes ?? []"
                    labelProperty="name"
                    (selectedValueChanged)="batchTypeSelected()"
                    valueProperty="id">
            </ws-select>
            </div>
            <div class="field-block">
                <label>Workflow:</label>
                <ws-select  
                    placeholder="Select"
                    [disabled]="!selections.batchTypeId"
                    [(ngModel)]="selections.workflowTypeId"
                    [options]="workflows"
                    (selectedOptionChanged)="workflowSelected()"
                    labelProperty="name"
                    valueProperty="id">
                </ws-select>
            </div>
            <div class="field-block">
                <label>Batch Status:</label>
                <ws-select  
                    placeholder="Select"
                    [disabled]="!selections.batchTypeId || selections.showCompleted"
                    [(ngModel)]="selections.batchStatusName"
                    [options]="batchStatuses"
                    (selectedOptionChanged)="makeItDirty()"
                    labelProperty="name"
                    valueProperty="name">
                </ws-select>
            </div>
        </div>
        <div class="third-block">
            <div class="field-block">
                <label>Batch No:</label>
                <input 
                    class="form-control input-sm"
                    style="width: 200px;"
                    type="text"
                    [(ngModel)]="selections.batchNoPattern"
                    (ngModelChange)="makeItDirty()"
                    currencyMask
                    [options]="{ allowNegative: false, precision: 0, prefix: ''}"
                    [min]="1"
                    [max]="9999999999">
            </div>
            <div class="field-block" style="align-items: start;">
                <label>Description:</label>
                <textarea 
                    (change)="makeItDirty()"
                    class="form-control"
                    [(ngModel)]="selections.descriptionPattern"
                    maxlength="500"
                    rows="3">

                </textarea>
            </div>
        </div>
        <div style="display: flex; flex-direction: column; justify-content: space-between;">
            <div class="form-check">
                <label class="form-check-label">
                    Show Completed
                    <input class="form-check-input" type="checkbox" (change)="showCompletedToggle()" [(ngModel)]="selections.showCompleted">
                </label>
            </div>
            <div style="display: flex; gap: 5px;">
                <button class="flat-button secondary-button small-button" (click)="clear()">Clear</button>
                <button 
                    class="flat-button primary-button small-button" 
                    [disabled]="gridLoading || disableAppyButton" 
                    (click)="apply()">
                        Apply
                </button>
            </div>
        </div>
    </div>
</div>