<div class="ws-flex-container-vertical ws-flex-auto" *ngIf="isInitialized">
    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" currentPageTitle="Responsible Entities" *ngIf="!isEmbeddedMode"></ws-bread-crumbs>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">
                Responsible Entities
                <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item" *ngIf="canEdit">
                    <button
                        type="button"
                        class="flat-button primary-button small-button"
                        (click)="addResponsibleEntity()"
                        helpTooltip
                        helpContentId="asset-list.new-asset"
                        position="bottom"
                        *ngIf="!siteId">New Responsible Entity</button>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button" class="flat-button small-button secondary-button" (click)="refresh()" helpTooltip helpContentId="app.refresh" position="bottom" [disabled]="refreshing"><i class="fa fa-refresh"></i></button>
                </div>
            </div>
        </div>
    </div>
    <ws-ag-grid-angular (gridReady)="onAgGridReady($event)"
                        [companyId]="companyId"
                        [gridOptions]="gridOptions"
                        [gridId]="gridId"
                        [exportOptions]="exportOptions"
                        [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                        [defaultSizeToFit]="false">
    </ws-ag-grid-angular>
</div>
