import {Injectable} from '@angular/core';
import { Dictionary } from 'lodash';
import { Picklist } from './Tax-Rate/Tax-Authority/tax.authority.component';

@Injectable(
    { providedIn: 'root' }
)
export class Constants {
    PropertyTypeNames: Dictionary<string>;
    ProcessingExceptionStatusText: Dictionary<string>;
    TransmittalOutputTypeAbbr: object;
    TransmittalOutputFormats: object;
    EntityTypes: object;
    EntityType: { COMPANY: string, SITE: string };
    TaskTypes: object;
    SiteClassIcons: object;
    PropertyTypes: object;
    ContractServiceTypes: { [key: string]: { id: number, name: string, fullDisplayName?: string } };
    ClientServiceTypes: object;
    WorkflowUserTypes: object;
    Roles: object;
    FactorTableTypes: Compliance.NameValuePair<Compliance.FactorTableTypeEnum>[];
    FilingTypes: Compliance.NameValuePair<Core.FilingTypes>[];
    RepresentationTypes: Compliance.NameValuePair<Compliance.RepresentationTypeEnum>[];
    FormTypes: Compliance.NameValuePair<Compliance.FormTypeEnum>[];
    ReturnFormFieldEntityTypes: Compliance.NameValuePair<Compliance.ReturnFormFieldEntityType>[];
    TaxableAssessmentTypes: { [taxableAssessmentTypeId: number]: { name: string, displayName: string, extraDisplay: string } };
    TaxAuthorityCategories: { [taxAuthorityCategoryId: number]: { name: string, displayName: string } };
    CertificationTypes: Picklist[];
    TaxAuthorityStatuses: Picklist[];
    ImageURLs: { [id: string]: string; };
    AccountingDurationTypes: any;
    PdfJsBase: string;

    constructor() {
        this.PropertyTypeNames = {
            1: 'Real Estate',
            2: 'Personal',
            3: 'Mineral',
            4: 'Centrally Assessed'
        };

        this.ProcessingExceptionStatusText = {
            1: 'Added in Error',
            2: 'Escalated',
            3: 'Rejected',
            4: 'Research Requested'
        };

        this.TransmittalOutputTypeAbbr = {
            1: 'PT',
            2: 'DT',
            3: 'AP',
            4: 'BP'
        };

        this.TransmittalOutputFormats = {
			ORIGINAL: {
				id: 1,
				name: 'Original (default)'
			},
			FMV_AV_LEGAL: {
				id: 2,
				name: 'FMV, AV & Legal'
			},
			FMV_AV_TV_RV_LEGAL: {
				id: 3,
				name: 'FMV, AV, TV, RV & Legal'
			},
			FISCAL_YEAR: {
				id: 4,
				name: 'Kellogg'
			},
            ALDI: {
                id: 5,
                name: 'Aldi'
            }
		};

        this.PropertyTypes = {
            REALESTATE: {
                id: 1,
                name: 'Real Estate',
                propTypeAbbr: 'RE'
            },
            PERSONAL: {
                id: 2,
                name: 'Personal',
                propTypeAbbr: 'PP'
            },
            MINERAL: {
                id: 3,
                name: 'Mineral',
                propTypeAbbr: 'MN'
            },
            CENTRALLYASSESSED: {
                id: 4,
                name: 'Centrally Assessed',
                propTypeAbbr: 'CA'
            },
            // This is used in the older code, so we need to keep both
            RE: {
                propTypeName: 'Real Estate',
                propTypeAbbr: 'RE',
                sequence: 1,
                propertyTypeID: 1
            },
            PP: {
                propTypeName: 'Personal',
                propTypeAbbr: 'PP',
                sequence: 2,
                propertyTypeID: 2
            },
            MN: {
                propTypeName: 'Mineral',
                propTypeAbbr: 'MN',
                sequence: 3,
                propertyTypeID: 3
            },
            CA: {
                propTypeName: 'Centrally Assessed',
                propTypeAbbr: 'CA',
                sequence: 4,
                propertyTypeID: 4
            }
        };

        this.ContractServiceTypes = {
            APPEAL: {
                id: 1,
                name: 'Appeal'
            },
            ADMIN: {
                id: 2,
                name: 'Admin',
                fullDisplayName: 'Administrative'
            },
            PREACQUISITION: {
                id: 3,
                name: 'Pre-Acquisition',
            },
            MISC: {
                id: 4,
                name: 'Miscellaneous'
            },
            ACCRUAL: {
                id: 5,
                name: 'Accruals'
            },
            ASMTREVIEW: {
                id: 6,
                name: 'Asmt Review',
                fullDisplayName: 'Assessment Review'
            },
            RETURN: {
                id: 7,
                name: 'Returns'
            },
            TAXBILL: {
                id: 8,
                name: 'Tax Bills',
                fullDisplayName: 'Tax Bill Administration'
            },
            HOURLY: {
                id: 9,
                name: 'Hourly'
            },
            LITIGATION: {
                id: 10,
                name: 'Litigation'
            }
        };

        this.ClientServiceTypes = {
            COMPLIANCERETURNS: {
                id: 1,
                name: 'Compliance Returns'
            },
            ASSESSMENTS: {
                id: 2,
                name: 'Assessments'
            },
            APPEALS: {
                id: 3,
                name: 'Appeals'
            },
            TAXBILLS: {
                id: 4,
                name: 'Tax Bills'
            },
            TAXBILLSTRANSMIT: {
                id: 5,
                name: 'Tax Bills - Transmit'
            },
            FREEPORT: {
                id: 6,
                name: 'Freeport'
            },
            ABATEMENT: {
                id: 7,
                name: 'Abatement'
            },
            POLLUTIONCONTROL: {
                id: 8,
                name: 'Pollution Control'
            },
            ALLOCATION: {
                id: 9,
                name: 'Allocation'
            }
        };

        this.TaskTypes = {
            35: 'Prepare Draft Invoice',
            36: 'Review Draft Invoice',
            37: 'Transfer Invoice to RIBS'
        };

        this.SiteClassIcons = {
            12: 'symbol_inter',
            19: 'freqchg',
            20: 'hospital-building',
            21: 'festival',
            22: 'factory',
            23: 'tree',
            24: 'townhouse',
            25: 'office-building',
            26: 'house',
            27: 'departmentstore',
            28: 'mall',
            29: 'letter_s',
            30: 'stadium',
            31: 'symbol_inter'
        };

        //todo: remove
        this.WorkflowUserTypes = {
            NA: {
                id: 0,
                name: 'N/A'
            },
            ACCOUNTHANDLER: {
                id: 1,
                name: 'Account Handler'
            },
            JURISDICTIONSPECIALIST: {
                id: 2,
                name: 'Jurisdiction Specialist'
            },
            USER: {
                id: 3,
                name: 'User'
            },
            CONSULTANT: {
                id: 4,
                name: 'Consultant'
            }
        };

        this.EntityTypes = {
            PaymentPackage: 19,
            Invoice: 21,
            Asset: 22
        };

        this.EntityType = {
            COMPANY: 'company',
            SITE: 'site'
        };

        this.Roles = {
            RYANPRIVATEITEMSVIEW: 'RyanPrivateItemsView',
            RYANPRIVATEITEMSEDIT: 'RyanPrivateItemsEdit',
            ALLOWDATAIMPORTS: 'AllowDataImports'
        };

        this.FactorTableTypes = [
            { name: 'Depreciation', value: Compliance.FactorTableTypeEnum.Depreciation },
            { name: 'Index', value: Compliance.FactorTableTypeEnum.Index }
        ];

        this.FilingTypes = [
            { name: 'PP Return', value: Core.FilingTypes.PPReturn },
            { name: 'Misc Filing', value: Core.FilingTypes.MiscFiling },
            { name: 'Freeport', value: Core.FilingTypes.Freeport },
            { name: 'Abatement', value: Core.FilingTypes.Abatement },
            { name: 'Pollution Control', value: Core.FilingTypes.PollutionControl }
        ];

        this.RepresentationTypes = [
            { name: 'Agent', value: Compliance.RepresentationTypeEnum.Agent },
            { name: 'Employee', value: Compliance.RepresentationTypeEnum.Employee },
            { name: 'Fiduciary', value: Compliance.RepresentationTypeEnum.Fiduciary },
            { name: 'Owner', value: Compliance.RepresentationTypeEnum.Owner },
            { name: 'Secured Party', value: Compliance.RepresentationTypeEnum.SecuredParty },
            { name: 'Other', value: Compliance.RepresentationTypeEnum.Other }
        ];

        this.FormTypes = [
            { name: 'Compliance', value: Compliance.FormTypeEnum.Compliance },
            { name: 'Appeal', value: Compliance.FormTypeEnum.Appeal }
        ];

        this.ReturnFormFieldEntityTypes = [
            { name: 'Appeal', value: Compliance.ReturnFormFieldEntityType.Appeal},
            { name: 'Assessment', value: Compliance.ReturnFormFieldEntityType.Assessment},
            { name: 'Assessor', value: Compliance.ReturnFormFieldEntityType.Assessor},
            { name: 'Company', value: Compliance.ReturnFormFieldEntityType.Company},
            { name: 'Filing', value: Compliance.ReturnFormFieldEntityType.Filing},
            { name: 'Filing Batch', value: Compliance.ReturnFormFieldEntityType.FilingBatch},
            { name: 'General Appeal Form', value: Compliance.ReturnFormFieldEntityType.GeneralAppealForm},
            { name: 'General Return Settings', value: Compliance.ReturnFormFieldEntityType.GeneralReturnSetting},
            { name: '', value: Compliance.ReturnFormFieldEntityType.None},
            { name: 'Parcel', value: Compliance.ReturnFormFieldEntityType.Parcel},
            { name: 'Parcel Descriptor', value: Compliance.ReturnFormFieldEntityType.ParcelDescriptor},
            { name: 'Site', value: Compliance.ReturnFormFieldEntityType.Site},
            { name: 'Site Descriptor', value: Compliance.ReturnFormFieldEntityType.SiteDescriptor},
            { name: 'Supplemental Information', value: Compliance.ReturnFormFieldEntityType.SupplementalField}
        ];

        this.TaxableAssessmentTypes = {
            1: { name: 'TotalAV', displayName: 'Total AV', extraDisplay: '(AV after exemptions have been applied)' },
            2: { name: 'PreAdjTotal', displayName: 'Pre AdjTotal', extraDisplay: '(AV prior to exemptions being applied)' },
            3: { name: 'AlternateAV', displayName: 'Alternate AV', extraDisplay: '' },
            4: { name: 'LandOnly', displayName: 'Land Only', extraDisplay: '' }
        };

        this.TaxAuthorityCategories = {
            1: {name: 'County', displayName: 'County'},
            2: {name: 'School', displayName: 'School'},
            3: {name: 'MunicipalityTownship', displayName: 'Municipality/Township'},
            4: {name: 'Miscellaneous', displayName: 'Miscellaneous'}
        };

        this.AccountingDurationTypes = {
            1: 'Monthly',
            2: 'Quarterly',
            3: '4-4-5',
            4: '4-5-4',
            5: '5-4-4'
        };

        this.CertificationTypes = [{
            label: 'Prior Prior',
            id: -2
        }, {
            label: 'Prior',
            id: -1
        }, {
            label: 'Current',
            id: 0
        }, {
            label: 'Next',
            id: 1
        }, {
            label: 'Next Next',
            id: 2
        }];

        this.TaxAuthorityStatuses = [
            {
                label: 'Pending QC',
                id: TaxAuthorityStatuses.Pending
            }, {
                label: 'Actual',
                id: TaxAuthorityStatuses.Actual
            }, {
                label: 'Estimated',
                id: TaxAuthorityStatuses.Estimated
            }
        ];

        this.ImageURLs = {
            ENTITY_MAP: '/images/googlemaps-icon.png',
            ATTACHMENTS_IMAGES: '/images/image_attachment.png',
            ATTACHMENTS_ALL_NONE: '/images/attachment_empty.png',
            ATTACHMENTS_ALL_SOME: '/images/attachment_some.png',
            ATTACHMENTS_IMAGES_ICON: 'fa-files-o',
            ATTACHMENTS_ALL_NONE_ICON: 'fa-paperclip',
            ATTACHMENTS_ALL_SOME_ICON: 'fa-files-o'
        };

        this.PdfJsBase = 'third-party/pdfjs/20241120/';
    }
}

export const RyanInstanceId = 1;
export const EmptyGuid = '00000000-0000-0000-0000-000000000000';

// Deprecated; use Core.EntityTypes instead
export enum EntityType {
    Company = 1,
    State = 2,
    Assessor = 3,
    Collector = 4,
    Site = 5,
    Parcel = 6,
    Assessment = 7,
    Appeal = 8,
    TaxBill = 9,
    Filing = 10,
    TaxPayment = 11,
    AnnualYear = 12,
    BillCluster = 13,
    Refund = 14,
    IntakeItem = 15,
    User = 16,
    Contact = 17,
    Task = 18,
    PaymentPackage = 19,
    PaymentTransmittal = 20,
    Invoice = 21,
    TaxAuthority = 28,
    TaxRateArea = 29,
    FormRevisionYearAssessor = 30,
    Budget = 31
}

export enum EntityTypeIds {
    COMPANY = 1,
    STATE = 2,
    ASSESSOR = 3,
    COLLECTOR = 4,
    SITE = 5,
    PARCEL = 6,
    ASSESSMENT = 7,
    APPEAL = 8,
    TAX_BILL = 9,
    FILING = 10,
    PAYMENT = 11,
    ANNUAL_YEAR = 12,
    BILL_CLUSTER = 13,
    REFUND = 14,
    INTAKE_ITEM = 15,
    USER = 16,
    CONTACT = 17,
    PAYMENTPACKAGE = 19,
    INVOICE = 21,
    ASSET = 22,
    FORM = 23,
    FORMREVISION = 24,
    FACTORTABLE = 25,
    FILINGBATCH = 26,
    INTAKE_BATCH = 27,
    TAXAUTHORITY = 28,
    TAXRATEAREA = 29,
    BUDGET = 31,
    ACCOUNTINGPERIOD = 32,
    ALLOCATION= 33,
    CLIENTSERVICE = 34,
    PROPERTYTYPE = 35,
    ASSESSMENTALLOCATION = 36,
    SUPPORTUSER = 37,
    PAYMENT_BATCH = 41,
    RESPONSIBLE_ENTITY = 42
}

export const EntityTypeLevels = {
    [Core.EntityTypes.Company]: 7,
    [Core.EntityTypes.Site]: 5,
    [Core.EntityTypes.Parcel]: 4,
    [Core.EntityTypes.AnnualAssessment]: 3,
    [Core.EntityTypes.Appeal]: 3,
    [Core.EntityTypes.Bill]: 2,
    [Core.EntityTypes.Filing]: 1,
    [Core.EntityTypes.Refund]: 3,
    [Core.EntityTypes.Budget]: 3
};

export enum EntityTypeNames {
    'Company' = 1,
    'State' = 2,
    'Assessor' = 3,
    'Collector' = 4,
    'Site' = 5,
    'Parcel' = 6,
    'Assessment' = 7,
    'Appeal' = 8,
    'Tax Bill' = 9,
    'PP Compliance' = 10,
    'Tax Payment' = 11,
    'Annual Year' = 12,
    'Bill Cluster' = 13,
    'Refund' = 14,
    'Intake' = 15,
    'User' = 16,
    'Contact' = 17,
    'PaymentPackage' = 19,
    'Invoice' = 21,
    'Asset' = 22,
    'Form' = 23,
    'Form Revision' = 24,
    'Factor Table' = 25,
    'Filing Batch' = 26,
    'Intake Batch' = 27,
    'Tax Authority' = 28,
    'Tax Rate Area' = 29,
    'Budget' = 31,
    'Accounting Period' = 32,
    'Allocation' = 33,
    'Payment Batch' = 41,
    'Responsible Entity' = 42
}

export enum PropertyType {
    RealEstate = 1,
    Personal = 2,
    Mineral = 3,
    CentrallyAssessed = 4
}

export enum ContractServiceType {
    Appeal = 1,
    Admin = 2,
    PreAcquisition = 3,
    Misc = 4,
    Accrual = 5,
    AsmtReview = 6,
    Return = 7,
    TaxBill = 8,
    Hourly = 9,
    Litigation = 10
}

// This isn't a list of all TaskTypes, just the ones that have been needed so far
export enum TaskType {
    FileAppeal = 6,
    PrintApplication = 24,
    PrepareDraftInvoice = 35,
    ReviewDraftInvoice = 36,
    TransferInvoiceToRIBS = 37,
    AdjustInvoice = 51,
    TransferUBRToRIBS = 58,
    RequestReliefOfUBR = 59
}

export enum CommentType {
    Budget = 1,
    PSR = 2
}

export enum CommentTypeNames {
    'Budget' = 1,
    'PSR' = 2,
    'Appeal Recommendation' = 3,
    'DRTT' = 4,
    'Accrual Export/Transmit' = 5,
    'Import' = 6,
    'DRTT Assessment Allocations Work Papers' = 7,
    'Market Review' = 8
}

export enum ContentTypes {
    KnownIssue = 1,
    ReleaseNotes = 2
}

export enum SavedSearchCategoryEnum {
    ComplianceReport = 200,
    ComplianceReturnReport = 201,
    CompliancePackageReport = 202,
    ComplianceCustomReport = 203
}

export enum ClientServiceType {
    ComplianceReturns = 1,
    Assessments = 2,
    Appeals = 3,
    TaxBills = 4,
    TaxBillTransmit = 5,
    Freeport = 6,
    Abatement = 7,
    PollutionControl = 8,
    Allocation = 9
}

export enum ActiveStatuses {
    Inactve = 0,
    ActivePending = 1,
    Active = 2
}

export enum TaxAuthorityStatuses {
    Estimated = 1,
    Pending = 2,
    Actual = 3
}

export enum IdleTimeoutModes {
    Activity = 'activity',
    KeepAlive = 'keepalive',
    Disabled = 'disabled'
}

export type efAction = 'update' | 'add' | 'delete';

export enum CommonBulkUpdateFieldActionEnum {
    NoChange = 0,
    SetToBlank = 1,
    ChangeTo = 2,
    RemoveOverride = 3
}

export enum AssessmentComponentTypeEnum {
    Normal = 1,
    Adjustment = 2,
    Cap = 3
}

export const enum ContactModalOrigin {
    ViewProfile = 1,
    ContactsList= 2,
    EntityContact = 3,
    NewContact = 4
}

export enum AdvancedSearchCategoryTypes {
    SYSTEM = 0,
    CUSTOM = 1,
    COMPANY = 2
}

export enum DefaultDisplayTypes {
    CRITERIA = 0,
    RESULTS = 1
}

export enum ActionViewDisplayTypes {
    FILTER = 0,
    OVERVIEW = 1,
    DETAILED = 2
}

export const enum EntityAddressDeliverabilityActionEnum {
    Revise = 1,
    Ignore = 2,
    Accept = 3,
    Unavailable = 4
}

export enum ActionViewCategoryTypes {
    SYSTEM = 0,
    CUSTOM = 1
}

export enum RevisionChangeTypes {
    ORIGINAL_TO_FINAL = 1,
    FINAL = 2,
    OTHER = 3,
    NO_CHANGE= 4
}

export enum TaskTypes {
    INFORMALHEARING = 8,
    FORMALHEARING = 9,
    SUBMITEVIDENCE = 27,
    INVOICEAPPEALSAVINGS = 34
}

export enum TaskActionTypes {
    COMPLETETASK = 3,
    RESCINDTASK = 4,
    REASSIGNTASK = 5,
    INSERTNEWAFTERTHISTASK = 6,
    DELETETASK = 7,
    SKIPTASK = 8,
    INSERTNEWBEFORETHISTASK = 9,
    GETINSERTOPTIONS = 10
}
