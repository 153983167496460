import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentBatchService } from '../../paymentBatch.service';
import { BusyIndicatorService } from 'src/app/Busy-Indicator';
import * as moment from 'moment';
import { chain, find, reject, some } from 'lodash';
import { ToastrService } from 'ngx-toastr';

export interface PaymentBatchFilterCriteria {
    companyIds: number[]
}

@Component({
    selector: 'payment-batch-filters',
    templateUrl: 'paymentBatchFilters.component.html',
    styleUrls: ['paymentBatchFilters.component.scss']
})

export class PaymentBatchFiltersComponent implements OnInit {
    constructor(
        private readonly _paymentBatchService: PaymentBatchService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _toastr: ToastrService
    ) { }

    @Input() gridLoading: boolean;
    @Output() applyFilters: EventEmitter<Core.PaymentBatchGridFilterSelectionsModel> =  new EventEmitter();

    filters: Core.PaymentBatchGridFiltersAvailableModel;
    selections: Core.PaymentBatchGridFilterSelectionsModel;
    availableSubCompanies: Core.NameIdPair[] = [];
    workflows: Core.NameIdPair[] = [{id: undefined, name: undefined}];
    batchStatuses: Core.NameIdPair[] = [{id: undefined, name: undefined}];
    disableAppyButton: boolean = false;

    private _dateTypeMap: { [key in Core.PaymentBatchGridFilterDateTypeEnum]: string } = {
        [Core.PaymentBatchGridFilterDateTypeEnum.None]: 'None',
        [Core.PaymentBatchGridFilterDateTypeEnum.BatchCreatedDate]: 'Batch Created Date',
        [Core.PaymentBatchGridFilterDateTypeEnum.FundingDueDate]: 'Funding Due Date',
        [Core.PaymentBatchGridFilterDateTypeEnum.EarliestDueDate]: 'Earliest Due Date',
        [Core.PaymentBatchGridFilterDateTypeEnum.BatchCompletedDate]: 'Batch Completed Date'
    };

    async ngOnInit() {
        this._initSelections();

        const busyRef = this._busyIndicatorService.show({message: 'Loading'});
        try {
            const filters = await this._paymentBatchService.getAvailablePaymentBatchGridFilters();
            filters.batchTypes.unshift({id: undefined, name: undefined});
            filters.workflowTypes.map(x => {
                x.workflows.unshift({id: undefined, name: undefined});
                return x;
            });
            filters.batchStatuses.map(x => {
                x.statuses.unshift({id: undefined, name: undefined});
                return x;
            });
            filters.dateTypes.map(x => {
                x.name = this._dateTypeMap[x.id];
                return x;
            });

            this.filters = filters;

            if(sessionStorage['pbGoBack']) {
                const selectionsString = sessionStorage['pbccFilters'];
                if(selectionsString) {
                    this.selections = JSON.parse(selectionsString);
                    this.apply();
                }

                sessionStorage.removeItem('pbGoBack');
            } else if(sessionStorage['fromTlCompany']) {
                const selectionsString = sessionStorage['pbccFilters'];

                if(selectionsString) {
                    const { tlCompanyIds } = JSON.parse(selectionsString) as Core.PaymentBatchGridFilterSelectionsModel;

                    if(some(this.filters.tlCompanies, { id: tlCompanyIds[0] })) {
                        this.selections.tlCompanyIds = tlCompanyIds;
                        this.apply();
                    } else {
                        this._toastr.info(`There are no payment batches for ${sessionStorage['fromTlCompany']}`);
                        sessionStorage.removeItem('pbccFilters');
                    }
                }

                sessionStorage.removeItem('fromTlCompany');
            }

        } finally {
            busyRef.hide();
        }
    }

    get dateTypeFilters(): Core.NameIdPair[] {
        if(!this.filters) {
            return [];
        }

        return this.selections.showCompleted
            ? this.filters.dateTypes
            : reject(this.filters.dateTypes, {
                id: Core.PaymentBatchGridFilterDateTypeEnum.BatchCompletedDate
            });
    }

    async getSubsidiaries() {
        this.makeItDirty();

        const busyRef = this._busyIndicatorService.show({message: 'Loading subsidiaries...'});

        try {
            const { subCompanies } = await this._paymentBatchService.getAvailablePaymentBatchGridSubsidiaries({
                tlCompanyIds: this.selections.tlCompanyIds
            });

            this.selections.subCompanyIds = [];
            this.availableSubCompanies = subCompanies;

        } finally {
            busyRef.hide();
        }
    }

    dateTypeChanged() {
        if(this.selections.dateFilter.dateType ) {
            if(this.selections.dateFilter.fromDate === undefined && this.selections.dateFilter.toDate === undefined) {
                this.selections.dateFilter.fromDate = moment().subtract(2, 'months').startOf('month').toDate();
                this.selections.dateFilter.toDate = moment().add(3, 'months').endOf('month').toDate();
            }
        } else {
            this.selections.dateFilter.fromDate = undefined;
            this.selections.dateFilter.toDate = undefined;
        }

        this.makeItDirty();
    }

    batchTypeSelected() {
        this.selections.workflowTypeId = undefined;
        this.selections.batchStatusName = undefined;

        const workflowType = find(this.filters?.workflowTypes, {
            batchTypeId: this.selections.batchTypeId
        });
        this.workflows = workflowType?.workflows || [];
        this.batchStatuses = this._populateBatchStatuses();

        this.makeItDirty();
    }

    workflowSelected() {
        this.selections.batchStatusName = undefined;
        this.batchStatuses = this._populateBatchStatuses();

        this.makeItDirty();
    }

    showCompletedToggle() {
        this.makeItDirty();

        if(!this.selections.showCompleted) {
            if(this.selections.dateFilter.dateType === Core.PaymentBatchGridFilterDateTypeEnum.BatchCompletedDate) {
                this.selections.dateFilter.dateType = Core.PaymentBatchGridFilterDateTypeEnum.None;
            }
        } else {
            this.selections.batchStatusName = undefined;
        }
    }

    clear() {
        this.makeItDirty();
        this._initSelections();
    }

    makeItDirty() {
        this.disableAppyButton = false;
    }

    apply() {
        sessionStorage['pbccFilters'] = JSON.stringify(this.selections);
        this.applyFilters.emit(this.selections);
        this.disableAppyButton = true;
    }

    private _initSelections() {
        this.selections = {
            dateFilter: {
                dateType: Core.PaymentBatchGridFilterDateTypeEnum.None
            },
            showCompleted: false,
            subCompanyIds: [],
            tlCompanyIds: []
        };
    }

    private _populateBatchStatuses(): Core.NameIdPair[] {
        if(this.selections.batchTypeId) {
            if(this.selections.workflowTypeId) {
                const batchStatusItem = find(this.filters?.batchStatuses, {
                    batchTypeId: this.selections.batchTypeId,
                    workflowTypeId: this.selections.workflowTypeId
                });

                return batchStatusItem?.statuses ?? [];
            } else {
                return chain(this.filters?.batchStatuses)
                    .filter({batchTypeId: this.selections.batchTypeId})
                    .flatMap('statuses')
                    .uniqBy('id')
                    .value();
            }
        } else {
            return [];
        }
    }
}