<div class="container">
	<div class="single-column-layout">
        <h1>ABOUT</h1>
        <div [style.display]="loading ? 'block' : 'none'" class="text-center" style="margin-top: 50px;">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>

        <div *ngIf="!loading && allAppVersionInfo" style="max-width: 600px;" class="about-panel">
            <h2>API</h2>
            <p>
                <span class="title">Assembly Version:</span>
                <span>{{allAppVersionInfo.apiAssemblyVersion}}</span>
            </p>
            <p>
                <span class="title">Branch Name:</span>
                <span>{{allAppVersionInfo.apiVersionInfo.branchName}}</span>
            </p>
            <p>
                <span class="title">Build Number:</span>
                <span>{{allAppVersionInfo.apiVersionInfo.buildNumber}}</span>
            </p>
            <p>
                <span class="title">Build Time:</span>
                <span>{{allAppVersionInfo.apiVersionInfo.buildTime}}</span>
            </p>
            <p>
                <span class="title">Commit:</span>
                <span>{{allAppVersionInfo.apiVersionInfo.commit}}</span>
            </p>
            <p>
                <span class="title">Last Relevant Commit:</span>
                <span>{{allAppVersionInfo.apiVersionInfo.lastRelevantCommit}}</span>
            </p>

            <h2>DB</h2>
            <p>
                <span class="title">Release Version:</span>
                <span>{{allAppVersionInfo.dbEpicVersion}}</span>
            </p>
            <p>
                <span class="title">Branch Name:</span>
                <span>{{allAppVersionInfo.dbVersionInfo.branchName}}</span>
            </p>
            <p>
                <span class="title">Build Number:</span>
                <span>{{allAppVersionInfo.dbVersionInfo.buildNumber}}</span>
            </p>
            <p>
                <span class="title">Build Time:</span>
                <span>{{allAppVersionInfo.dbVersionInfo.buildTime}}</span>
            </p>
            <p>
                <span class="title">Commit:</span>
                <span>{{allAppVersionInfo.dbVersionInfo.commit}}</span>
            </p>
            <p>
                <span class="title">Last Relevant Commit:</span>
                <span>{{allAppVersionInfo.dbVersionInfo.lastRelevantCommit}}</span>
            </p>

            <h2>UI</h2>
            <p>
                <span class="title">Branch Name:</span>
                <span>{{uiVersionInfo.branchName}}</span>
            </p>
            <p>
                <span class="title">Build Number:</span>
                <span>{{uiVersionInfo.buildNumber}}</span>
            </p>
            <p>
                <span class="title">Build Time:</span>
                <span>{{uiVersionInfo.buildTime}}</span>
            </p>
            <p>
                <span class="title">Commit:</span>
                <span>{{uiVersionInfo.commit}}</span>
            </p>
            <p>
                <span class="title">Last Relevant Commit:</span>
                <span>{{uiVersionInfo.lastRelevantCommit}}</span>
            </p>

            <h2>JSReport</h2>
            <ng-container *ngIf="reportVersionInfo">
                <p>
                    <span class="title">Branch Name:</span>
                    <span>{{reportVersionInfo.branchName}}</span>
                </p>
                <p>
                    <span class="title">Build Number:</span>
                    <span>{{reportVersionInfo.buildNumber}}</span>
                </p>
                <p>
                    <span class="title">Build Time:</span>
                    <span>{{reportVersionInfo.buildTime}}</span>
                </p>
                <p>
                    <span class="title">Commit:</span>
                    <span>{{reportVersionInfo.commit}}</span>
                </p>
                <p>
                    <span class="title">Last Relevant Commit:</span>
                    <span>{{reportVersionInfo.lastRelevantCommit}}</span>
                </p>
            </ng-container>
        </div>
        <div>
            <button class="flat-button small-button" *ngIf="!reportVersionInfo && !loading" (click)="getJSReportVersionInfo()">Get JSReport Version Info</button>
        </div>
    </div>
</div>
