import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { BusyIndicatorService } from '../Busy-Indicator';
import { BulkSaveExceptionResult } from '../Client-Services/Exceptions/client-service-exception.models';
import { IWeissmanModalComponent } from '../Compliance/WeissmanModalService';
import { Constants } from '../constants.new';
import { ConsultingEngagementModalParams } from './consultingEngagements.component';
import { ConsultingEngagementsRepository } from './consultingEngagements.repository';

@Component({
    selector: 'consulting-engagement-modal',
    templateUrl: './consultingEngagementModal.component.html'
})

export class ConsultingEngagementModalComponent implements OnInit, OnDestroy, IWeissmanModalComponent<ConsultingEngagementModalParams, any> {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _fb: UntypedFormBuilder,
        private readonly _engagementsRepository: ConsultingEngagementsRepository,
        private readonly constants: Constants,
        private _toastrService: ToastrService,
        private readonly _bsModalRef: BsModalRef) {
    }

    engagementForm: UntypedFormGroup;
    params: ConsultingEngagementModalParams;
    result: any;
    assignedUserTeam: Core.UserTeamModel = {} as Core.UserTeamModel;
    csrs: Core.ConsultingEngagementCSR[] = [];
    accountHandlerChanged: boolean = false;
    isLoading: boolean = false;
    instanceId: number;
    assignedGroups: Core.GroupModel[] = [];
    availableGroups: Core.GroupModel[] = [];
    selectedGroupId: number;
    addingNewGroup: boolean = false;

    private _destroy$: Subject<void> = new Subject();

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.engagementForm = this._fb.group({
            accountHandler: [null, Validators.required]
        });

        const userGroups = await this._engagementsRepository.getUserGroups();

      //if existing engagement - check for csr's already assigned
      if(this.params.engagement.accepted) {
          this.assignedUserTeam = this.transformAccountHandler(this.params.engagement.accountHandler);

          const engagementInfo = await this._engagementsRepository.getEngagementInfo(this.params.engagement.consultingEngagementId);

          this.csrs = engagementInfo.consultingEngagementCSRs;

          this.assignedGroups = this._sortUserGroups(engagementInfo.userGroups);
          this.availableGroups = this._sortUserGroups(userGroups.filter(x => !engagementInfo.userGroups.find(y => y.groupID === x.groupID)));
      } else {
          this.availableGroups = this._sortUserGroups(userGroups);
      }

      this.isLoading = false;
    }

    //TODO: some properties on the AccountHandlerResponse class don't match the UserTeamMember class - might want to return one directly
    transformAccountHandler (accountHandler: Core.AccountHandlerResponse): Core.UserTeamModel {
        return {
            firstName: accountHandler.firstName,
            lastName: accountHandler.lastName,
            userID: accountHandler.userId as string,
            teamID: accountHandler.teamId,
            teamName: accountHandler.teamName
        } as unknown as Core.UserTeamModel;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    getPropTypeAbbr(responsibility): string {
        const propertyType : any = _.find(Object.values(this.constants.PropertyTypes), { id: responsibility.propertyTypeId });
        return propertyType.propTypeAbbr;
    }

    setNewUser(newUser: Core.UserTeamModel) {
        this.assignedUserTeam = newUser;

        this.accountHandlerChanged = this.params.engagement.accountHandler && this.assignedUserTeam && (this.assignedUserTeam.teamID !== this.params.engagement.accountHandler.teamId || this.assignedUserTeam.userID !== this.params.engagement.accountHandler.userId);
    }

    getSaveLabel():string {
        if(this.params.engagement.accepted === false) {
            return 'Accept';
        }
        else {
            return 'Save';
        }

    }

    async saveEngagement(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Updating consulting engagement' });

        const engagementRequest : Core.ConsultingEngagementDetails = {
            accountHandlerTeamId: this.assignedUserTeam.teamID,
            accountHandlerUserId: this.assignedUserTeam.userID,
            consultingEngagementId: this.params.engagement.consultingEngagementId,
            userGroupIds: this.assignedGroups.map(x => x.groupID)
        };

        try {
            //new engagement
            if(this.params.engagement.accepted === false) {
                this.result = await this._engagementsRepository.accept(engagementRequest);
                this._toastrService.success(`Consulting engagement request from ${this.params.engagement.name} accepted successfully.`);
            }
            //existing engagement
            else {
                this.result = await this._engagementsRepository.update(engagementRequest);
                const hasErrors = this.handleUpdateErrors(this.result.results);

                if(!hasErrors)
                {
                    if (this.result.longRunningProcessId)
                    {
                        this._toastrService.success('This change has requested a large number of updates to tasks. They are being run in the background, and you\'ll be notified when they are done.');
                    }
                    else
                    {
                        this._toastrService.success(`Consulting engagement with ${this.params.engagement.name} updated successfully.`);
                    }
                }
            }
        } finally {
            busyRef.hide();
        }
            this.cancel();
    }

    handleUpdateErrors(updateInfo: Core.BulkExceptionSetResult[]) : boolean {
        let anyErrors = false;

        if(!!updateInfo && updateInfo.length > 0)
        {
            const isNotAuthorized = (x: BulkSaveExceptionResult) => !x.isAuthorized;
            const hasErrors = (x: BulkSaveExceptionResult) => !!x.errorMessage;

            if (_.every(updateInfo, isNotAuthorized)) {
                this._toastrService.error('You are not authorized to perform this operation');
                anyErrors = true;
            }
            else if (_.some(updateInfo, isNotAuthorized)) {
                this._toastrService.error('Some exceptions could not be saved because you are not authorized to perform this operation');
                anyErrors = true;
            }

            if (_.every(updateInfo, hasErrors)) {
                this._toastrService.error('There was an unexpected error processing your request');
                anyErrors = true;
            }
            else if (_.some(updateInfo, hasErrors)) {
                this._toastrService.error('Some exceptions could not be saved because there was an error processing your request');
                anyErrors = true;
            }
        }

        return anyErrors;
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    deleteUserGroup(group: Core.GroupModel) {
        const index = this.assignedGroups.indexOf(group);
        this.assignedGroups.splice(index, 1);
        this.availableGroups.push(group);

        this._sortUserGroups(this.assignedGroups);
        this._sortUserGroups(this.availableGroups);
    }

    selectUserGroupToAdd() {
        this.addingNewGroup = true;
    }

    addUserGroup() {
        const group = this.availableGroups.find(x => x.groupID === +this.selectedGroupId);

        this.assignedGroups.push(group);
        const groupIndex = this.availableGroups.indexOf(group);
        this.availableGroups.splice(groupIndex, 1);

        this._sortUserGroups(this.assignedGroups);
        this._sortUserGroups(this.availableGroups);

        this.selectedGroupId = null;
    }

    cancelAddUserGroup() {
        this.selectedGroupId = null;
        this.addingNewGroup = false;
    }

    private _sortUserGroups(userGroups: Core.GroupModel[]) : Core.GroupModel[] {
        return userGroups.sort((x, y) => x.name.localeCompare(y.name));
    }
}
