import { Component, Inject, Input } from '@angular/core';

@Component({
    selector: 'responsible-entity-panel',
    templateUrl: './responsibleEntityPanel.component.html',
})
export class ResponsibleEntityPanelComponent {
    @Input() companyId: number;
    @Input() siteId?: number;
    @Input() parcelId?: number;
    @Input() enableAllocations: boolean;

    loading: boolean = true;
    showBody: boolean = false;
    isMaximized: boolean = false;

    constructor(@Inject('$rootScope') private _rootScope: any) {
    }

    expandOrCollapse(): void {
        if (this.isMaximized) {
            return;
        }

        this.showBody = !this.showBody;
    }

    onLoadingChanged(loading: boolean) {
        this.loading = loading;
    }

    maximize(event): void {
        this.isMaximized = true;
        this.showBody = true;
        this._broadcastMaximize();
        event.stopPropagation();
    }

    unmaximize(event): void {
        this.isMaximized = false;
        this._broadcastMaximize();
        event.stopPropagation();
    }

    private _broadcastMaximize(): void {
        this._rootScope.$broadcast('maximize_change', {
            panelName: 'responsibleEntityPanel',
            maximized: this.isMaximized
        });
    }
}
