<div class="ws-flex-container-horizontal ws-stretch"
     [ngClass]="wrapperClass">
    <div class="grid-wrapper ws-flex-auto">
        <ag-grid-angular class="ag-theme-balham grid grid-cell-no-focus"
                         [ngClass]="gridClass"
                         [gridOptions]="gridOptions"
                         [rowSelection]="rowSelection"
                         [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                         [pinnedTopRowData]="pinnedTopRowData"
                         (gridReady)="onAgGridReady($event)"
                         (selectionChanged)="selectionChanged.emit($event)"
                         (bodyScroll)="bodyScroll.emit($event)"
                         (columnResized)="columnResized.emit($event)"
                         (filterChanged)="filterChanged.emit($event)"
                         (cellClicked)="cellClicked.emit($event)"
                         (sortChanged)="sortChanged.emit($event)">
        </ag-grid-angular>
    </div>
    <ng-container *ngIf="hasToolbar">
        <ag-grid-tool-panel [companyId]="companyId"
                            [gridOptions]="gridOptions"
                            [gridId]="gridId"
                            [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                            [isBulkDeleteVisible$]="isBulkDeleteVisible$"
                            [isCustomButtonVisible$]="isBulkDeleteVisible$"
                            [exportOptions]="exportOptions"
                            [showConfigureLayout]="showConfigureLayout"
                            [showResizeColumns]="showResizeColumns"
                            [defaultSizeToFit]="defaultSizeToFit"
                            [defaultSizeToContent]="defaultSizeToContent"
                            [defaultColorizeHeaders]="defaultColorizeHeaders"
                            [exportIcon]="exportIcon"
                            [exportHelpContentId]="exportHelpContentId"
                            [additionalActions]="additionalActions"
                            [gridReady]="agGridReady"
                            (toolPanelReady)="toolPanelReady()"
                            (bulkUpdate)="bulkUpdate.emit()"
                            (bulkDelete)="bulkDelete.emit()"
                            (filtersEnabled)="filtersEnabled.emit($event)"
                            (sizeColumnsToFit)="sizeColumnsToFit.emit()"
                            (sizeColumnsToContent)="sizeColumnsToContent.emit()"
                            (customButtonClick)="customButtonClick.emit()"
                            (layoutSpecificationChanged)="layoutChanged.emit($event)"
                            [additionalButtons]="additionalButtons">
        </ag-grid-tool-panel>
    </ng-container>
</div>
