import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AllAppVersionInfo, VersionInfo } from './about.models';

@Injectable()
export class AboutService {
    constructor(private http: HttpClient) { }

    async getAllAppVersionInfo() {
        return await lastValueFrom(this.http.get<AllAppVersionInfo>('/api/AppInfo/getAll'));
    }

    async GetUIVersionInfo() {
        return await lastValueFrom(this.http.get<VersionInfo>('/assets/UIVersionInfo.json'));
    }

    async getJSReportVersionInfo() {
        return await lastValueFrom(this.http.get<VersionInfo>('/api/AppInfo/JSReportVersionInfo'));
    }
}
