import { Injectable } from '@angular/core';
import { AttachmentModalData, AttachmentModalEntityData } from '../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../Comments/comments.service';
import { SDHttpService } from '../../Common/Routing/sd-http.service';
import { TaskService } from '../../Task/task.service.upgrade';
import { AnnualDetailsService } from '../annual-details.service';
import { AnnualDetailAssessment, AnnualDetailYear } from '../Annual-Year/annual-year.model';
import { AssessmentService } from '../Assessments/assessment.service';
import { Appeal, AppealLevel, AppealStatus, AppealStatusID } from './appeal.model';
import { Decimal } from 'decimal.js';
import { BulkOperationResult } from '../../Common/Models/bulk-operation-result.model';

declare const _: any;

// TODO: Handle global consts in Angular 2+
const APPEAL_ENTITY_TYPE_ID = 8;

export class AppealViewDTO {
    annualDetailYear: AnnualDetailYear;
    appeals: Appeal[];
    stateID: number;
}

@Injectable()
export class AppealService {
    constructor(private annualDetailsService: AnnualDetailsService, private assessmentService: AssessmentService,
        private http: SDHttpService, private taskService: TaskService) { }

    //******************** API Calls ********************
    getStateAppealLevels(stateID: number): Promise<AppealLevel[]> {
        return this.http.get("/api/appeallevel/state/" + stateID);
    }

    getAppealsForAnnualYear(annualYearID: number): Promise<Appeal[]> {
        return this.http.get("/api/appeal/annualyear/" + annualYearID);
    }

    getAppealView(appealID: number): Promise<AppealViewDTO> {
        return this.http.get("api/annualassessmentview/appeal/" + appealID).then((result: any) => {
            // The server calls the annualDetailYear "annualYearView", so we'll change the name here
            result.annualDetailYear = result.annualYearView;
            delete result.annualYearView;
            return result as AppealViewDTO;
        });
    }

    saveAppeal(appeal: Appeal): Promise<Appeal> {
        return this.http.put("/api/appeal", appeal);
    }

    createAppeal(appeal: Appeal): Promise<Appeal> {
        // Note that many properties of appeal will be ignored; as of this comment, we're only
        // saving the appeal level ID and assessment ID, leaving everything else at defaults

        // Also, some properties that we don't have selectable on the UI are required by the UI
        if (!appeal.appealStatusID) {
			appeal.appealStatusID = null;	//AppealStatusID.Open;  it's nullable now, see WK-2102
        }

        // Note that the server normally fills in a property called "appealLevel" on appeal objects,
        // but it is not returned on the create API call. The Appeal model on the UI does not have
        // an appealLevel property, so it shouldn't create any problems, but if we ever add the
        // appealLevel property to the UI model and lean on it, we should correct that issue here.
        return this.http.post('/api/appeal', appeal);
    }

    deleteAppeal(appealID: number): Promise<void> {
        return this.http.delete('/api/appeal/' + appealID);
    }
    //****************** End API Calls ******************

    loadTaskSummary(viewModel: AppealViewModel) {
        viewModel.currentTaskSummary = null;
        viewModel.loadingTaskSummary = true;
        let appealID = viewModel.currentAppeal.appealID;
        this.taskService.getTaskSummaryByEntity(appealID, APPEAL_ENTITY_TYPE_ID).then((taskSummary) => {
            // If we've navigated to another appeal by the time this happens, don't pay attention to the result
            if (appealID === viewModel.currentAppeal.appealID) {
                viewModel.loadingTaskSummary = false;
                viewModel.currentTaskSummary = taskSummary[0];
            }
        });
    }

    setCurrentAppeal(viewModel: AppealViewModel, appealID: number): void {
        let appealIndex = _.findIndex(viewModel.model, (appeal) => {
            return appeal.appealID === appealID;
        });

        if (appealIndex >= 0) {
            this.setAppealIndex(viewModel, appealIndex);
        }
    }

    setAppealIndex(viewModel: AppealViewModel, appealIndex: number, force: boolean = false): void {
        if (force || viewModel.currentAppealIndex !== appealIndex) {
            viewModel.currentAppealIndex = appealIndex;
            viewModel.currentAppeal = viewModel.model[appealIndex];
            viewModel.resetEdit();
            viewModel.updateModalData();
            this.loadTaskSummary(viewModel);
        }
    }

    setAppealByAssessmentID(viewModel: AppealViewModel, assessmentID: number): void {
        let appealIndex = _.findIndex(viewModel.model, (appeal: Appeal) => {
            return appeal.annualAssessmentID === assessmentID;
        });

        if (appealIndex >= 0) {
            this.setAppealIndex(viewModel, appealIndex);
        }
    }

    addNewAppealToViewModel(viewModel: AppealViewModel, appeal: Appeal): Promise<Appeal> {
        let originalModel = viewModel.model || [];
        viewModel.model = [];
        // HACK: For whatever reason, ngx-bootstrap's tab control didn't handle this
        // correctly without the setTimeout; this gives it time to "breathe" after resetting
        // the list
        return new Promise<Appeal>(resolve => {
            setTimeout(() => {
                originalModel.unshift(appeal);
                viewModel.model = originalModel;
                this.setAppealIndex(viewModel, 0, true);
                resolve(appeal);
            }, 1);
        });
    }

    saveAppealFromViewModel(viewModel: AppealViewModel): Promise<Appeal> {
        return this.saveAppeal(viewModel.currentAppeal);
    }

    cancelAppealEdit(viewModel: AppealViewModel): void {
        viewModel.cancelEdit();
    }

    toggleAppealEdit(viewModel: AppealViewModel, editMode: boolean): void {
        if (editMode) {
            viewModel.beginEdit();
        }
    }

    // Whenever the user manually updates the "Savings" field, the "savingsOverriden" flag on the
    // appeal is set. For the specified appeal, this function computes the savings if they have
    // not been overriden.
    setSavings(appeal: Appeal, annualYearModel: AnnualDetailYear): Promise<void> {
        if (appeal.savingsOverridden) {
            return Promise.resolve();
        }

        let revisionAppealed = _.find(annualYearModel.annualGridDetails, (assessment: AnnualDetailAssessment) => {
            return assessment.annualAssessmentID === appeal.annualAssessmentID;
        }) as AnnualDetailAssessment;

        let revisionAppealedFMV = this.annualDetailsService.getTotalFMV(revisionAppealed.annualGridComponents);
        if (this.isClosed(appeal)) {
            let outcomeRevision = <AnnualDetailAssessment>_.find(annualYearModel.annualGridDetails, { annualAssessmentID: appeal.outcomeAnnualAssessmentID });
            if (outcomeRevision) {
                // let outcomeRevisionFMV = this.annualDetailsService.getTotalFMV(outcomeRevision.annualGridComponents);

                //  return this._getSavingsBaseRate(appeal.appealID, annualYearModel.annualYearID)
                //      .then((savingsBaseRate: number) => {
                //          appeal.savings = Decimal.max(0, new Decimal(revisionAppealedFMV).times(100).minus(new Decimal(outcomeRevisionFMV).times(100)).times(savingsBaseRate).dividedBy(100).toNumber()).toNumber();
                //      })

                // WK-7747 - Savings used to be calculated on the UI, but as it has become more complex that has made less sense over time.
                return this.http.post(`/api/appeal/${appeal.appealID}/fetchsavings`, {
                    appealId: appeal.appealID,
                    annualAssessmentId: appeal.annualAssessmentID,
                    outcomeRevisionId: outcomeRevision.annualAssessmentID,
                    appealStatusId: appeal.appealStatusID,
                    anticipatedFMV: appeal.anticipatedFMV
                }).then((newSavings: number) => {
                    appeal.savings = +newSavings;
                });
            } else {
                appeal.savings = null;
            }
        }
        else {
            if (!appeal.anticipatedFMV) {
                appeal.savings = undefined;
                return Promise.resolve();
            }

            return this._getSavingsBaseRate(appeal.appealID, annualYearModel.annualYearID)
                .then((savingsBaseRate: number) => {
                    appeal.savings = new Decimal(revisionAppealedFMV).times(100).minus(new Decimal(appeal.anticipatedFMV).times(100)).times(savingsBaseRate).dividedBy(100).toNumber();
                })
        }

        if (appeal.savings < 0) {
            appeal.savings = 0;
        }

        return Promise.resolve()
    }

    private _getSavingsBaseRate(appealID: number, annualYearID: number): Promise<number> {
        return this.http.get(`api/appeal/${appealID}/baserate/${annualYearID}`);
    }

    isClosed(appeal: Appeal): boolean {
        if (!appeal.appealStatusID) {
            return false;
        }

        let status = AppealStatus.getByID(appeal.appealStatusID);
        return status.isClosedStatus;
    }

    addPrepareApplicationTasks(taskIds: number[]): Promise<BulkOperationResult[]> {
        return this.http.put('api/tasks/addprepareapplicationtasks', taskIds);
    }

    //*************** ViewModel factories ***************
    private buildAppealViewModel(appeals: Appeal[], appealLevels: AppealLevel[], annualYearModel: AnnualDetailYear, parcelID: number, parcelAcctNum: string): AppealViewModel {
        let viewModel = new AppealViewModel();
        viewModel.stateAppealLevels = appealLevels;
        viewModel.annualYearModel = annualYearModel;
        viewModel.model = appeals;
        viewModel.showTabs = true;
        viewModel.parcelID = parcelID;
        viewModel.parcelAcctNum = parcelAcctNum;
        if (viewModel.model.length < 1) {
            viewModel.currentAppealIndex = -1;
            viewModel.currentAppeal = null;
        }
        else {
            let latestAppealIdx = 0;

            const latestAppeal= _.maxBy(appeals, x => new Date(x.addDate));
            if(latestAppeal) {
                latestAppealIdx = _.findIndex(appeals, {appealID: latestAppeal.appealID});
            }

            this.setAppealIndex(viewModel, latestAppealIdx);
        }

        return viewModel;
    }

    getAppealViewModelByYear(annualYearModel: AnnualDetailYear, parcelID: number, parcelAcctNum: string, stateID: number, defaultAppealID?: number): Promise<AppealViewModel> {
        return Promise.all([this.getStateAppealLevels(stateID), this.getAppealsForAnnualYear(annualYearModel.annualYearID)])
            .then((result) => {
                let viewModel = this.buildAppealViewModel(result[1], result[0], annualYearModel, parcelID, parcelAcctNum);
                viewModel.defaultAppealID = defaultAppealID;

                return viewModel;
            });
    }

    getAppealViewModelByAppealID(appealID: number): Promise<AppealViewModel> {
        return this.getAppealView(appealID).then((appealView) => {
            return this.getStateAppealLevels(appealView.stateID).then((appealLevels) => {
                let viewModel = this.buildAppealViewModel(appealView.appeals, appealLevels, appealView.annualDetailYear, null, null);
                viewModel.defaultAppealID = appealID;
                this.setCurrentAppeal(viewModel, appealID);
                viewModel.showTabs = false;

                return viewModel;
            });
        });
    }
    //************* End ViewModel factories *************
}

export class AppealViewModel {
    model: Appeal[];
    annualYearModel: AnnualDetailYear;
    stateAppealLevels: AppealLevel[];
    loadingTaskSummary: boolean;
    currentTaskSummary: any;
    currentAppealIndex: number;
    currentAppeal: Appeal;
    parcelID: number;
    parcelAcctNum: string;
    showTabs: boolean;
    defaultAppealID: number;
    commentModalData: CommentModalData;
    attachmentModalData: AttachmentModalData;
    hasWritePermission: boolean;
    submitEvidenceValid: boolean;
    informalHearingValid: boolean;
    hearingValid: boolean;
    resetDateTimeHandler: () => void;
    private preEditModelBackup: Appeal;

    constructor() {
        // This will be set by the appeal component when loaded
        this.hasWritePermission = false;

        this.submitEvidenceValid = true;
        this.informalHearingValid = true;
        this.hearingValid = true;
        this.resetDateTimeHandler = null;
    }

    // Usually, the backup object used for cancelling will be set whenever a navigation event happens,
    // and the navigation service informs us we need to handle it in the beginEdit handler. However,
    // within the appeal control, there are some circumstances where the backup must be created again.
    // At the moment, those circumstances are:
    // 1. Switching tabs
    // 2. An automatic save occurring before opening the task modal
    resetEdit(force: boolean = false): void {
        if (force || (this.currentAppeal &&
                this.preEditModelBackup &&
                this.currentAppeal.appealID !== this.preEditModelBackup.appealID)) {
            this.preEditModelBackup = _.cloneDeep(this.currentAppeal) as Appeal;
            console.log(['Creating preEditModelBackup', this.preEditModelBackup]);
            if (this.resetDateTimeHandler) {
                this.resetDateTimeHandler();
            }
        }
    }

    beginEdit(): void {
        if (this.currentAppeal) {
            this.preEditModelBackup = _.cloneDeep(this.currentAppeal) as Appeal;
            if (this.resetDateTimeHandler) {
                this.resetDateTimeHandler();
            }
        }
        else {
            this.preEditModelBackup = undefined;
            if (this.resetDateTimeHandler) {
                this.resetDateTimeHandler();
            }
        }
    }

    cancelEdit(): void {
        if (this.currentAppeal.appealID === this.preEditModelBackup.appealID) {
            _.assign(this.currentAppeal, this.preEditModelBackup);
            if (this.resetDateTimeHandler) {
                this.resetDateTimeHandler();
            }
        }
        else {
            throw new Error("Sanity check failed; attempted to restore backup with different appeal ID than current. Current appeal ID: " +
                this.currentAppeal.appealID + ", backup appeal ID: + " + this.preEditModelBackup.appealID);
        }
    }

    assignFromExistingAppeal(appeal: Appeal): void {
        if (!this.currentAppeal) {
            throw new Error("Invalid attempt to load appeal from existing data when no current appeal is set");
        }

        if (this.currentAppeal.appealID !== appeal.appealID) {
            throw new Error("Invalid attempt to appeal appeal with appealID " + appeal.appealID +
                " into appeal with appealID " + this.currentAppeal.appealID);
        }

        _.assign(this.currentAppeal, appeal);
        if (this.resetDateTimeHandler) {
            this.resetDateTimeHandler();
        }
    }

    appealLevelAbbrDisplay(appealLevelID: number): string {
        let appealLevel = _.find(this.stateAppealLevels, (appealLevel) => {
            return appealLevel.appealLevelID === appealLevelID;
        }) as AppealLevel;

        return appealLevel === undefined ? '' : appealLevel.abbr;
    }

    appealLevelLitigationDisplay(appealLevelId: number): string {
        let appealLevel = _.find(this.stateAppealLevels, (appealLevel) => {
            return appealLevel.appealLevelID === appealLevelId;
        }) as AppealLevel;

        return appealLevel === undefined
            ? ''
            : appealLevel.litigation ? 'Yes' : 'No';
    }

    setParcelDetails(parcelID: number, parcelAcctNum: string): void {
        this.parcelID = parcelID;
        this.parcelAcctNum = parcelAcctNum;
        this.updateModalData();
    }

    updateModalData(): void {
        let appeal = this.currentAppeal;
        if (appeal) {
            let appealLevel = this.appealLevelAbbrDisplay(appeal.appealLevelID);
            let description = this.annualYearModel.annualYear1 + ' - ' + appealLevel;

            // comment data
            this.commentModalData = new CommentModalData();
            this.commentModalData.entityID = appeal.appealID;
            this.commentModalData.entityTypeID = APPEAL_ENTITY_TYPE_ID;
            this.commentModalData.canEdit = this.hasWritePermission;
            this.commentModalData.parcelID = this.parcelID;
            this.commentModalData.parcelAcctNum = this.parcelAcctNum;
            this.commentModalData.description = description;
            this.commentModalData.year = this.annualYearModel.annualYear1.toString();

            // attachment data
            this.attachmentModalData = new AttachmentModalData();
            this.attachmentModalData.belowParcelEntity = new AttachmentModalEntityData();
            this.attachmentModalData.belowParcelEntity.id = appeal.appealID;
            this.attachmentModalData.belowParcelEntity.typeId = APPEAL_ENTITY_TYPE_ID;
            this.attachmentModalData.belowParcelEntity.name = appealLevel;
            this.attachmentModalData.entityType = 'Appeal';
            this.attachmentModalData.parentId = this.parcelID;
            this.attachmentModalData.parentType = 'Parcel';
            this.attachmentModalData.entityName = this.parcelAcctNum;
            this.attachmentModalData.entityDescription = description;
            this.attachmentModalData.year = this.annualYearModel.annualYear1;
        }
    }

    validate(callback: (boolean, string) => void): void {
        var invalidTimes = [];

        // For now I believe this is only possible by entering a date but not a time
        // TODO: Write this in a less crappy way
        if (!this.submitEvidenceValid) {
            invalidTimes.push('Submit Evidence');
        }
        if (!this.informalHearingValid) {
            invalidTimes.push('Informal Hearing');
        }
        if (!this.hearingValid) {
            invalidTimes.push('Formal Hearing');
        }

        let validationMessage = '';

        switch (invalidTimes.length) {
            case 1:
                validationMessage = ' date/time not valid';
                break;
            case 2:
                validationMessage = invalidTimes[0] + ' and ' + invalidTimes[1] + ' date/time not valid';
                break;
            case 3:
                validationMessage = invalidTimes[0] + ', ' + invalidTimes[1] + ', and ' + invalidTimes[2] + ' date/time not valid';
                break;
        }

        let appealStatusID = this.currentAppeal.appealStatusID;
        if ((appealStatusID === AppealStatusID.Reduction || (appealStatusID === AppealStatusID.Closed && this.currentAppeal.savings > 0)) && !this.currentAppeal.savingsDate) {
            validationMessage = 'Savings Date is required' + (validationMessage === '' ? '' : ('; ' + validationMessage));
        }
        else if (this.currentAppeal.appealStatusID === AppealStatusID.Null && this.currentAppeal.savingsDate) {
            validationMessage = 'Savings Date is not valid unless the appeal is closed' + (validationMessage === '' ? '' : ('; ' + validationMessage));
        }

        if (validationMessage !== '') {
            callback(false, validationMessage);
        }
        else {
            callback(true, '');
        }
    }
}
