import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CompanyService } from '../../Entity/Company/company.service';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { BusyIndicatorRef, BusyIndicatorService } from '../../Busy-Indicator';
import { RestrictService } from '../../Common/Permissions/restrict.service';
import { ToastrService } from 'ngx-toastr';
import { ResponsibleEntityRepository } from '../responsbileEntity.repository';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { BreadCrumb } from '../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { ColDef, GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../Compliance/AgGrid';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../Compliance/AgGrid/MultiSelectTracker';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../Compliance/AgGrid/ToolPanel/models';
import { BehaviorSubject, lastValueFrom, Subscription } from 'rxjs';
import { ResponsibleEntityAgGridDataSource, ResponsibleEntityDataSourceParams } from './agGridDataSource';
import {
    AgGridLinkCellRenderer,
    AgGridLinkCellRendererParams
} from '../../Compliance/AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import {
    AgGridYesNoFloatingFilterComponent
} from '../../Compliance/AgGrid/FloatingFilters/agGridYesNoFloatingFilter.component';
import { ResponsibleEntityListGridActionCellRendererComponent } from './agGridActionCellRenderer.component';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import {
    ResponsibleEntityDetailsComponent,
    ResponsibleEntityDetailsParams
} from '../Responsible-Entity-Details/responsibleEntityDetails.component';
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;
import AccessRightsEnum = Core.AccessRightsEnum;
import { DecimalPipe } from '@angular/common';
import { WeissmanDateFormatPipe } from '../../UI-Lib/Pipes';

@Component({
    selector: 'responsible-entity-list',
    templateUrl: './responsibleEntityList.component.html',
    styleUrls: ['./responsibleEntityList.component.scss']
})
export class ResponsibleEntityListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _companyService: CompanyService,
        private readonly _messageModalService: MessageModalService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _restrictService: RestrictService,
        private readonly _toastr: ToastrService,
        private readonly _responsibleEntityRepository: ResponsibleEntityRepository,
        private readonly _modalService: WeissmanModalService,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _datePipe: WeissmanDateFormatPipe
    ) {
    }

    @Input() companyId: number;
    @Input() siteId?: number;
    @Input() parcelId?: number;
    @Output() onLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

    breadcrumbs: BreadCrumb[] = [];
    canEdit: boolean = false;
    isInitialized: boolean = false;
    gridTracker: AgGridMultiSelectTracker;
    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    hasSelectedRows: boolean = false;
    refreshing: boolean = false;
    isEmbeddedMode: boolean;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Core.ResponsibleEntityModel).responsibleEntityId)
        }
    })
        .withColumnPinning()
        .buildDefault(this);

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[], fileFormat: Compliance.ExportFileFormatEnum): Promise<AgGridExportStartLRP> => {
            const searchParams = this._gridDataSource.getSearchParamsWithoutPagination();

            const exportModel: Core.ResponsibleEntityExportModel = {
                columnFilters: searchParams.columnFilters,
                sortColumns: searchParams.sortColumns,
                selectedRows: this.gridTracker.getSelectedRowsModel(),
                fileFormat: fileFormat,
                columnsToReturn: columnsToReturn,
                siteId: this.siteId,
                parcelId: this.parcelId
            };

            const lrp$ = this._responsibleEntityRepository.startExport(this.companyId, exportModel);
            const longRunningProcessId = await lastValueFrom(lrp$);
            return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.ExportResponsibleEntities };
        },
        canCancel: true,
        showFileFormatSelection: true
    }

    private _gridApi: GridApi;
    private _gridDataSource: ResponsibleEntityAgGridDataSource;
    private _gridMultiSelectSub: Subscription;
    private _canEdit: boolean = false;
    private _companyGridId: System.Guid = 'B41B12F0-56CD-49D7-8167-71CFE38838BF';
    private _siteGridId: System.Guid = '45F8F7B8-BB45-413E-BBA9-6AB2E3675312';
    private _parcelGridId: System.Guid = '4CF6D3AB-89A2-4B75-988C-7E1D16C2810B';

    async ngOnInit(): Promise<void> {
        this.isEmbeddedMode = !!this.siteId || !!this.parcelId;

        if (!this.companyId) {
            this.companyId = parseInt(this._routerService.getQuerystringParam('companyId'));
        }

        const [canView, canEdit] = await Promise.all([
            this._restrictService.hasCompanyPermission(this.companyId, AccessRightsEnum.Read),
            this._restrictService.hasCompanyPermission(this.companyId, AccessRightsEnum.Write)
        ]);

        this._canEdit = canEdit;

        if (!canView) {
            this._routerService.go('unauthorizedAccess', {});
        }

        let busyRef: BusyIndicatorRef;

        if (!this.isEmbeddedMode) {
            busyRef = this._busyIndicatorService.show({message: 'Loading'});
        }

        try {
            this.canEdit = await this._restrictService.hasCompanyPermission(this.companyId, Core.AccessRightsEnum.Write);

            const company: Core.CompanyModel = await this._companyService.getInfoForCompliance(this.companyId);

            this.breadcrumbs.push({
                name: company.companyName,
                target: 'company',
                options: { companyId: this.companyId }
            });

            this.isInitialized = true;
        } finally {
            if (!this.isEmbeddedMode) {
                await busyRef.hide();
            } else {
                this.onLoading.emit(false);
            }
        }
    }

    ngOnDestroy(): void {
        this._gridMultiSelectSub && this._gridMultiSelectSub.unsubscribe();
    }

    async onAgGridReady(event: GridReadyEvent): Promise<void> {
        this._gridApi = event.api;
        this._gridApi.showLoadingOverlay();

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        const columns: ColDef[] = this._getColumns();

        const defaultSortModel = [
            {
                colId: 'dueDate',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);

        this._setDataSource();

        this._gridApi.sizeColumnsToFit();

        this._gridMultiSelectSub = this.gridTracker.selectedRows$.subscribe(() => {
            const isBulkUpdateVisible = this.gridTracker.hasSelectedRows();
            // this.isBulkUpdateVisible$.next(isBulkUpdateVisible);
            this.hasSelectedRows = isBulkUpdateVisible;
        });

    }

    refresh() {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this._gridDataSource.refresh();
    }

    async addResponsibleEntity(): Promise<void> {
        const modalParams: ResponsibleEntityDetailsParams = {
            companyId: this.companyId,
            canEdit: true
        };

        const result = await this._modalService.showAsync(ResponsibleEntityDetailsComponent, modalParams, 'modal-xl');

        if (!result) {
            return Promise.resolve();
        }

        this.refresh();
        return Promise.resolve();
    }

    get gridId(): System.Guid {
        return this.siteId
            ? this._siteGridId
            : this.parcelId
                ? this._parcelGridId
                : this._companyGridId;
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        const dataSourceParams = (): ResponsibleEntityDataSourceParams => {
            return {
                companyId: this.companyId,
                siteId: this.siteId,
                parcelId: this.parcelId
            }
        }

        this._gridDataSource = new ResponsibleEntityAgGridDataSource(this._gridApi, this._responsibleEntityRepository, dataSourceParams);
        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }

    private _getColumns() {
        const columns = [
            {
                headerName: '',
                field: 'responsibleEntityId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                lockPosition: true,
                editable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {tracker: this.gridTracker} as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {tracker: this.gridTracker} as AgGridMultiSelectRendererParams,
                pinnedRowCellRenderer: () => {
                    return '';
                }
            },
            {
                headerName: 'Entity Name',
                field: 'name',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParamsStartsWith,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                pinned: true
            },
            {
                headerName: 'Entity Type',
                field: 'responsibleEntityType',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParamsStartsWith,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Entity Number',
                field: 'number',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParamsWithEqualTo,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'Address One',
                field: 'address1',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Address Two',
                field: 'address2',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'City',
                field: 'city',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'State',
                field: 'state',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Zip',
                field: 'zip',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Contact',
                field: 'contact',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Phone',
                field: 'phone',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Email',
                field: 'email',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Company Code',
                field: 'companyCode',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-company',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const responsibleEntity = params.data as Core.ResponsibleEntityModel;
                        if (!responsibleEntity) {
                            return '';
                        }
                        return `#/company/${responsibleEntity.companyId}`;
                    }
                } as AgGridLinkCellRendererParams,
                hide: true
            },
            {
                headerName: 'Company Name',
                field: 'companyName',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-company',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const responsibleEntity = params.data as Core.ResponsibleEntityModel;
                        if (!responsibleEntity) {
                            return '';
                        }
                        return `#/company/${responsibleEntity.companyId}`;
                    }
                } as AgGridLinkCellRendererParams
            }];

        let embeddedColumns: ColDef[] = [];

        if (this.isEmbeddedMode) {
            if (!this.parcelId) {
                embeddedColumns = [
                    {
                        headerName: 'Parcel Account Number',
                        field: 'parcelAccountNumber',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    }
                ];
            }

            embeddedColumns.push(
                {
                    headerName: 'Percentage',
                    field: 'percentage',
                    type: 'numericColumn',
                    width: AgGridColumns.numericColumnWidth,
                    filter: 'agNumberColumnFilter',
                    filterParams: AgGridFilterParams.numberWithRangeFilterAndEqualToParams,
                    floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                    valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                    cellClass: 'ag-numeric-cell'
                },
                {
                    headerName: 'Start Date',
                    field: 'startDate',
                    width: AgGridColumns.dateColumnWidth,
                    filter: 'agDateColumnFilter',
                    filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                    floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                    valueFormatter: (params) => this._datePipe.transform(params.value, true),
                    hide: true
                },
                {
                    headerName: 'End Date',
                    field: 'endDate',
                    width: AgGridColumns.dateColumnWidth,
                    filter: 'agDateColumnFilter',
                    filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                    floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                    valueFormatter: (params) => this._datePipe.transform(params.value, true),
                    hide: true
                });
        }

        const additionalColumns = [
            {
                headerName: 'Alloc Debit Account',
                field: 'allocationDebitAccount',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Alloc Credit Account',
                field: 'allocationCreditAccount',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Alloc Receivable Account',
                field: 'allocationReceivableAccount',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Allocations Export',
                field: 'allocationsExport',
                width: AgGridColumns.textColumnMedWidth,
                filter: 'agYesNoColumnFilter',
                filterParams: AgGridFilterParams.yesNoFilterParams,
                floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const responsibleEntity = params.data as Core.ResponsibleEntityModel;
                    return responsibleEntity
                        ? responsibleEntity.allocationsExport ? 'Yes' : 'No'
                        : '';
                },
                hide: true
            },
            {
                headerName: 'Accruals Export',
                field: 'accrualsExport',
                width: AgGridColumns.textColumnMedWidth,
                filter: 'agYesNoColumnFilter',
                filterParams: AgGridFilterParams.yesNoFilterParams,
                floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const responsibleEntity = params.data as Core.ResponsibleEntityModel;
                    return responsibleEntity
                        ? responsibleEntity.accrualsExport ? 'Yes' : 'No'
                        : '';
                },
                hide: true
            },
            {
                colId: 'grid-column-actions',
                headerName: '',
                field: 'actions',
                width: AgGridColumns.getActionColumnWidth(2),
                minWidth: AgGridColumns.getActionColumnWidth(2),
                maxWidth: AgGridColumns.getActionColumnWidth(2),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                pinned: 'right',
                lockVisible: true,
                sortable: false,
                cellRendererFramework: ResponsibleEntityListGridActionCellRendererComponent,
                cellRendererParams: {
                    viewDetails: this._viewDetails.bind(this),
                    canEdit: this._canEdit
                } as ResponsibleEntityListGridActionCellRendererComponent,
                pinnedRowCellRenderer: () => {return '';}
            }
        ];

        return [...columns, ...embeddedColumns, ...additionalColumns];
    }

    private async _viewDetails(params: ResponsibleEntityListGridActionCellRendererComponent): Promise<void> {
        const modalParams: ResponsibleEntityDetailsParams = {
            companyId: this.companyId,
            data: params.data as Core.ResponsibleEntityModel,
            canEdit: this.canEdit
        };

        const result = await this._modalService.showAsync(ResponsibleEntityDetailsComponent, modalParams, 'modal-xl');

        if (!result) {
            return Promise.resolve();
        }

        const allNodesOnPage: RowNode[] = [];
        this._gridApi.forEachNode(x => {
            const gridRow = x.data as Core.ResponsibleEntityModel;
            if (gridRow.responsibleEntityId === result.responsibleEntityId) {
                x.data = result;
                allNodesOnPage.push(x);
            }
        });
        this._gridApi.redrawRows({rowNodes: allNodesOnPage});

        return Promise.resolve();
    }
}
