import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../Common/Data/base.repository';
import { Observable } from 'rxjs';
import { PaymentPackage } from '../Processing/Transmittal/transmittal.models';

@Injectable()
export class PaymentBatchRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    private _baseUrl: string = '/api/paymentbatch';

    getPaymentBatchList(searchModel: Core.PaymentBatchSearchModel): Observable<Compliance.QueryResultModel<Core.PaymentBatchModel>> {
        return this.httpPost(`${this._baseUrl}/list`, searchModel);
    }

    deletePaymentBatch(paymentBatchId: number): Observable<boolean> {
        return this.httpDelete(`${this._baseUrl}/${paymentBatchId}`);
    }

    exportList(exportModel: Compliance.AssessorCommandCenterExportModel): Observable<any> {
        return this.httpPost(`${this._baseUrl}/export`, exportModel);
    }

    savePaymentBatch(request: Core.PaymentBatchCreateRequestModel): Observable<Core.PaymentBatchModel> {
        return this.httpPost(`${this._baseUrl}/create`, request);
    }

    getPaymentBatchDetailById(paymentBatchId: number): Observable<Core.PaymentBatchModel> {
        return this.httpGet(`${this._baseUrl}/${paymentBatchId}/paymentBatchDetails`);
    }

    savePaymentBatchDetails(paymentBatchId: number, paymentBatch: Core.PaymentBatchModel): Observable<Core.PaymentBatchModel> {
        return this.httpPut(`${this._baseUrl}/${paymentBatchId}/paymentBatchDetails`, paymentBatch);
    }

    getPaymentBatchWorkflowStatusById(paymentBatchId: number): Observable<Core.PaymentBatchDetailsWorkflowStatusModel> {
        return this.httpGet(`${this._baseUrl}/${paymentBatchId}/workflowstatus`);
    }

    getPaymentBatchPaymentsList(searchModel: Core.PaymentBatchDetailsSearchModel): Observable<Compliance.QueryResultWithTotalsModel<Core.PaymentBatchDetailsEntryModel, Core.PaymentBatchPaymentTotalsModel>> {
        return this.httpPost(`${this._baseUrl}/details/${searchModel.paymentBatchId}/list`, searchModel);
    }

    getPaymentBatchPaymentsRowIds(searchModel: Core.PaymentBatchDetailsSearchModel): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`${this._baseUrl}/details/${searchModel.paymentBatchId}/rowIds`, searchModel);
    }

    deletePaymentRow(paymentBatchId: number, paymentId: number): Observable<boolean> {
        return this.httpDelete(`${this._baseUrl}/details/${paymentBatchId}/payment/${paymentId}`);
    }

    startPaymentBatchDetailsPaymentBulkDelete(deleteModel: Core.PaymentBatchDetailsPaymentBulkDeleteModel): Observable<number> {
        return this.httpPut(`${this._baseUrl}/details/${deleteModel.paymentBatchId}/payments/delete`, deleteModel);
    }

    paymentBatchDetailsPaymentBulkReReview(model: Core.PaymentBatchDetailsPaymentBulkReReviewModel): Observable<number> {
        return this.httpPut(`${this._baseUrl}/details/${model.paymentBatchId}/payments/reReview`, model);
    }

    getTaskSeriesConfig(): Observable<Core.PaymentBatchConfigModel[]> {
        return this.httpGet(`${this._baseUrl}/taskSeriesConfig`);
    }

    getDeliveryDetailPaymentBatch(entityId: number): Observable<Core.PaymentBatchDeliveryDetail> {
        return this.httpGet(`${this._baseUrl}/deliveryDetailPaymentBatch/${entityId}`);
    }

    getAvailablePaymentBatchesForAddPayments(tlCompanyId: number, taskIds: number[]): Observable<Core.PaymentBatchModel[]> {
        return this.httpPost(`${this._baseUrl}/${tlCompanyId}/AvailableForAddPayments`, taskIds);
    }

    getPaymentBatchPaymentList(searchModel: Core.PaymentBatchAddPaymentSearchModel): Observable<Compliance.QueryResultWithTotalsModel<Core.PaymentBatchAddPaymentModel, Core.PaymentBatchPaymentTotalsModel>> {
        return this.httpPost(`${this._baseUrl}/${searchModel.paymentBatchId}/payments`, searchModel);
    }

    getPaymentBatchPaymentRowIds(searchModel: Core.PaymentBatchAddPaymentSearchModel): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`${this._baseUrl}/${searchModel.paymentBatchId}/payments/rowIds`, searchModel);
    }

    addPaymentsToPaymentBatch(paymentBatchId: number, request: Core.PaymentBatchAddPaymentRequestModel): Observable<Core.PaymentBatchAddPaymentResultModel> {
        return this.httpPut(`${this._baseUrl}/${paymentBatchId}/payments`, request);
    }

    addPaymentsToBatchByTaskId(paymentBatchId: number, taskIds: number[]): Observable<Core.BulkOperationResult[]> {
        return this.httpPut(`${this._baseUrl}/${paymentBatchId}/tasks`, taskIds);
    }

    advanceTask(paymentBatchId: number, updateModel: Core.PaymentBatchTaskUpdateModel): Observable<Core.PaymentBatchTaskUpdateResultModel> {
        return this.httpPost(`${this._baseUrl}/${paymentBatchId}/task/complete`, updateModel);
    }

    rescindTask(paymentBatchId: number, updateModel: Core.PaymentBatchTaskUpdateModel): Observable<Core.PaymentBatchTaskUpdateResultModel> {
        return this.httpPost(`${this._baseUrl}/${paymentBatchId}/task/rescind`, updateModel);
    }

    reassignTask(paymentBatchId: number, updateModel: Core.PaymentBatchTaskUpdateModel): Observable<Core.PaymentBatchTaskUpdateResultModel> {
        return this.httpPost(`${this._baseUrl}/${paymentBatchId}/task/reassign`, updateModel);
    }

    // getExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
    //     const options = {
    //         observe: 'response',
    //         responseType: 'blob'
    //     };

    //     return this.httpGet(`${this._baseUrl}/export/${longRunningProcessId}`, options);
    // }

    getAllowedBatchTypes(companyId: number): Observable<Core.PaymentBatchCreationBatchTypesModel> {
        return this.httpGet(`${this._baseUrl}/${companyId}/AllowedBatchTypes`);
    }

    finalizeTransmittalPackage(paymentBatchId: number, taskId: number, packages: PaymentPackage[]):Observable<any>{
        return this.httpPost(`${this._baseUrl}/${paymentBatchId}/task/${taskId}/finalize`, packages);

    }
    getTransmittalPaymentPackages(paymentBatchId: number): Observable<any> {
        return this.httpGet(`${this._baseUrl}/${paymentBatchId}/transmittalPackagesList`);
    }

    getBillPaymentPackages(paymentBatchId: number): Observable<Core.PaymentBatchFeedPackage[]> {
        return this.httpGet(`${this._baseUrl}/${paymentBatchId}/billPayPackagesList`);
    }

    getOutputZip(paymentBatchId: number, feedTypes: Core.PaymentBatchFeedTypes[]): Observable<number> {
        return this.httpPost(`${this._baseUrl}/${paymentBatchId}/generateOutputZip`, feedTypes);
    }

    getFilesAndFeeds(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`${this._baseUrl}/export/output/${longRunningProcessId}`, options);
    }

    getPaymentBatchSettings(paymentBatchId: number): Observable<Core.PaymentBatchSettingsModel> {
        return this.httpGet(`${this._baseUrl}/${paymentBatchId}/settings`);
    }

    savePaymentBatchSettings(paymentBatchId: number, settings: Core.PaymentBatchSettingsModel): Observable<Core.PaymentBatchSettingsModel> {
        return this.httpPut(`${this._baseUrl}/${paymentBatchId}/settings`, settings);
    }

    startPaymentBatchExport(exportModel: Compliance.PaymentBatchExportModel): Observable<number> {
        return this.httpPost(`${this._baseUrl}/generateGridExport`, exportModel);
    }

    startPaymentBatchDetailsExport(exportModel: Compliance.PaymentBatchDetailsExportModel): Observable<number> {
        return this.httpPost(`${this._baseUrl}/details/generateGridExport`, exportModel);
    }

    startPaymentBatchCheckImport(paymentBatchId: number, file: FormData): Observable<number> {
        return this.httpPost(`${this._baseUrl}/${paymentBatchId}/checkimport`, file, {
            headers: new HttpHeaders({
                'Content-Type': [undefined]
            })
        });
    }

    getPaymentBatchCheckImport(paymentBatchId: number): Observable<Core.PaymentBatchCheckImportModel> {
        return this.httpGet(`${this._baseUrl}/${paymentBatchId}/checkimport`);
    }

    savePaymentBatchCheckImport(paymentBatchId: number, paymentBatchCheckImportId: number): Observable<any> {
        return this.httpPut(`${this._baseUrl}/${paymentBatchId}/checkimport/${paymentBatchCheckImportId}`, {});
    }

    discardPaymentBatchCheckImport(paymentBatchId: number, paymentBatchCheckImportId: number): Observable<any> {
        return this.httpDelete(`${this._baseUrl}/${paymentBatchId}/checkimport/${paymentBatchCheckImportId}`);
    }

    getAvailablePaymentBatchGridFilters(): Observable<Core.PaymentBatchGridFiltersAvailableModel> {
        return this.httpGet(`${this._baseUrl}/availablefilters`);
    }

    getAvailablePaymentBatchGridSubsidiaries(request: Core.PaymentBatchGridFilterSubCompaniesRequestModel): Observable<Core.PaymentBatchGridFilterSubCompaniesResponseModel> {
        return this.httpPost(`${this._baseUrl}/availablesubsidiaryfilters`, request);
    }
}
