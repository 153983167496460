<button class="flat-button icon-button primary button" [popover]="termPopover" [outsideClick]="true" #chooseTerm="bs-popover"
    triggers="" (click)="lookupTerm()" (onHidden)="saveTerm()" [disabled]="selectedTerm.contractServiceTypeId == ContractServiceType.Misc || selectedTerm.contractServiceTypeId == ContractServiceType.Hourly">
    <i class="fa fa-binoculars"></i>
</button>

<ng-template #termPopover>
    <div *ngIf="selectedTerm.contractServiceTypeId === ContractServiceType.Appeal || selectedTerm.contractServiceTypeId === ContractServiceType.Litigation">
        <input id="contingency"
            type="number"
            [(ngModel)]="termToEdit.contingencyPct"
            class="form-control contract-currency"
            (keyup.enter)="chooseTerm.hide()"
            max="100"
            min="0">% Contingency Fee
    </div>
    <div *ngIf="selectedTerm.contractServiceTypeId === ContractServiceType.Appeal || selectedTerm.contractServiceTypeId === ContractServiceType.Litigation">
        <input type="text"
            [(ngModel)]="termToEdit.contingencyCap"
            currencyMask
            [options]="{allowNegative: false}"
            class="form-control contract-currency"
            (keyup.enter)="chooseTerm.hide()"
            [disabled]="!termToEdit.contingencyPct"> Contingency Cap
    </div>
    <div>
        <input id="flatFee"
            type="text"
            [(ngModel)]="termToEdit.flatFee"
            currencyMask
            [options]="{allowNegative: false}"
            class="form-control contract-currency"
            (keyup.enter)="chooseTerm.hide()"> Fixed Fee
    </div>
<!--
WK-4211 Hide (temporarily) the Flat Fee and Hourly Rate options for contract terms
    <div class="radio">
        <label>
            <input type="radio" [(ngModel)]="selectedTermType" value="flatFee" (ngModelChange)="selectedTermTypeChanged()">
        </label>
    </div>
    <div class="radio">
        <label>
            <input type="radio" [(ngModel)]="selectedTermType" value="hourlyRate" (ngModelChange)="selectedTermTypeChanged()">
            <input id="hourlyRate" type="text" [(ngModel)]="termToEdit.hourlyRate" currencyMask [options]="{allowNegative: false}" class="form-control contract-currency"
                [disabled]="selectedTermType !== 'hourlyRate'" (keyup.enter)="chooseTerm.hide()"> Hourly Rate
        </label>
    </div>
-->
</ng-template>
