import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, lastValueFrom } from 'rxjs';
import { ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community';
import { PaymentBatchCommandCenterAgGridDataSource, PaymentBatchListDataSourceParams } from './agGridDataSource';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { HelpService } from '../../UI-Lib/Help-Tooltip';
import { PAYMENT_BATCH_COMMAND_CENTER_HELP } from './paymentBatch.component.help';
import { BusyIndicatorService } from '../../Busy-Indicator';
import { AgGridOptionsBuilder } from '../../Compliance/AgGrid';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { PAYMENT_BATCH_LIST_COLUMNS } from './paymentBatch.columns';
import { ICellRendererParamsForPaymentBatchListGridAction } from './agGridActionCellRenderer.component';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { PaymentBatchRepository } from '../../Core-Repositories/paymentBatch.repository';
import { CreatePaymentBatchModalComponent } from '../Create-Payment-Batch-Modal/createPaymentBatchModal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PaymentBatchService } from '../paymentBatch.service';
import { TypeaheadCompany } from 'src/app/Entity/Company/Company.Picker.Component';
import { AgGridExportOptions, AgGridExportStartLRP } from 'src/app/Compliance/AgGrid/ToolPanel/models';


@Component({
    selector: 'payment-batch-command-center',
    templateUrl: './paymentBatch.component.html',
    styleUrls: ['./paymentBatch.component.scss']
})
export class PaymentBatchCommandCenterComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _paymentBatchService: PaymentBatchService,
        private readonly _paymentBatchRepository: PaymentBatchRepository,
        private readonly _messageModalService: MessageModalService,
        private readonly _modalService: BsModalService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _helpService: HelpService,
        private readonly _busyService: BusyIndicatorService,
        public readonly _decimalPipe: DecimalPipe,
        public readonly _currencyPipe: CurrencyPipe) { }
    canEdit: boolean = true;
    isInitialized: boolean = false;
    gridFilters: Core.PaymentBatchGridFilterSelectionsModel;
    readonly gridId: System.Guid = 'D4997CE4-F879-4C32-A893-5EC6856D6365';
    columnApi: ColumnApi;
    gridOptions = new AgGridOptionsBuilder()
        .withColumnPinning()
        .buildDefault(this);
    gridDataSource: PaymentBatchCommandCenterAgGridDataSource;

    exportOptions: AgGridExportOptions = {
        start: async (
            columnsToReturn: Compliance.NameValuePair<string>[],
            fileFormat: Compliance.ExportFileFormatEnum
        ): Promise<AgGridExportStartLRP> => {
            const searchModel = this.gridDataSource.getSearchParamsWithoutPagination();

            const lrp$ = this._paymentBatchRepository.startPaymentBatchExport({
                searchModel,
                columnsToReturn,
                fileFormat
            });

            const longRunningProcessId = await lastValueFrom(lrp$);
            const longRunningProcessTypeId = Compliance.LongRunningProcessTypeEnum.PaymentBatchExportList;

            return { longRunningProcessId, longRunningProcessTypeId };
        },
        canCancel: true,
        getDisabled: () => !this.gridDataSource || this.gridDataSource.totalRows === 0 || this.gridDataSource.isRefreshing
    };
    overlayNoRowsString: string = 'Please select filter criteria to see results';

    private _gridApi: GridApi;
    private _destroy$: Subject<void> = new Subject();

    get refreshHelpContentId(): string {
        if (!this.canGetRows) {
            return 'payment-batch-command-center.filter-disabled';
        }

        return 'app.refresh';
    }

    get canGetRows(): boolean {
        return !!this.gridFilters;
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(PAYMENT_BATCH_COMMAND_CENTER_HELP);
        this.isInitialized = true;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        this._setColumnDefinitions();

        const defaultSortModel = [
            {
                colId: 'assessorAbbr',  // change
                sort: 'asc'
            }
        ];

        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
    }

    refresh(): void {
        this._refreshDataSource();
    }

    launch(){
        const modalRef = this._modalService.show(CreatePaymentBatchModalComponent);
    }

    onViewDetails(params: ICellRendererParamsForPaymentBatchListGridAction) {
        const paymentBatch = params.data as Core.PaymentBatchModel;
        this._navigateToPaymentBatchDetails(paymentBatch);
    }

    async delete(params: ICellRendererParamsForPaymentBatchListGridAction): Promise<void> {
        const paymentBatch = params.data as Core.PaymentBatchModel;
        if (!paymentBatch) {
            return;
        }

        const deleted = await this._deletePaymentBatch(paymentBatch);
        if (deleted) {
            this._refreshDataSource();
        }
    }

    canDelete(params: ICellRendererParamsForPaymentBatchListGridAction): boolean {
        const paymentBatch = params.data as Core.PaymentBatchModel;
        if (!paymentBatch) {
            return false;
        }

       // return this.canEdit && !paymentBatch.batchStatus;
       return paymentBatch.editDeleteAllowed;
    }

    formatCurrency(value: number): string {
        if(value == null) {
            return '';
        }

        const formattedVal = this._currencyPipe.transform(Math.abs(value), 'USD', 'symbol-narrow');
        return value < 0 ? `(${formattedVal})` : formattedVal;
    }

    applyFilters(gridFilters: Core.PaymentBatchGridFilterSelectionsModel) {
        this.gridFilters = gridFilters;
        this._refreshDataSource();
        this.overlayNoRowsString = undefined;
    }

    private async _setColumnDefinitions(): Promise<void> {
        const columns = PAYMENT_BATCH_LIST_COLUMNS(this);
        this._gridApi.setColumnDefs(columns);
    }

    private _refreshDataSource(): void {
        if (!this.gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this.gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this.gridDataSource) {
            return;
        }

        const dataSourceParams = (): PaymentBatchListDataSourceParams => {
            return {
                gridFilters: this.gridFilters,
                canGetRows: this.canGetRows
            };
        };

        this.gridDataSource = new PaymentBatchCommandCenterAgGridDataSource(
            this._gridApi,
            dataSourceParams,
            this._paymentBatchService);

        this._gridApi.setDatasource(this.gridDataSource);

        return true;
    }

    private _navigateToPaymentBatchDetails(paymentBatch: Core.PaymentBatchModel): void {
        if (!paymentBatch) {
            return;
        }
        // this._productAnalyticsService.logEvent('access-payment-batch', {
        //     returnAccessPath: 'view return payment'
        // });
        this._routerService.go('paymentBatchDetails',
            {
                'paymentBatchId': paymentBatch.paymentBatchId
            });
    }

    private async _deletePaymentBatch(paymentBatch: Core.PaymentBatchModel): Promise<boolean> {
        try {
            await this._messageModalService.confirm('Are you sure you wish to delete the payment batch?', 'Confirm Delete');
        }
        catch (e) {
            return Promise.resolve(false);
        }

        const busyRef = this._busyService.show({ message: 'Deleting' });

        try {
            await lastValueFrom(this._paymentBatchRepository.deletePaymentBatch(paymentBatch.paymentBatchId));
        }
        finally {
            busyRef.hide();
        }

        return Promise.resolve(true);
    }
}
