import { Component, OnInit, OnDestroy } from '@angular/core';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { ReturnService } from './return.service';
import { BusyIndicatorService, BusyIndicatorRef, IBusyIndicatorConfig } from '../../Busy-Indicator';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Subject, lastValueFrom } from 'rxjs';
import { IMutexServiceHandler, WeissmanMutexService } from '../WeissmanMutexService';
import { TaskError } from './Models/taskError';
import { NavigationService } from '../../Layout/navigation.service';
import { AttachmentModalData } from '../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../Comments/comments.service';
import { WeissmanModalService } from '../WeissmanModalService';
import { ReturnFormsErrorComponent } from './Return-Forms-Error/returnFormsError.component';
import { RestrictService, Roles, InstanceRights } from '../../Common/Permissions/restrict.service';
import { takeUntil } from 'rxjs/operators';
import { FilingBatchRepository, LongRunningProcessRepository } from '../Repositories';
import { BreadCrumb } from '../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { HelpService } from '../../UI-Lib/Help-Tooltip';
import { RETURN_PAGE_HELP } from './returnPage.component.help';
import { TimerService } from '../../UI-Lib/Utilities';
import { ReturnAssetsService } from './Return-Parts/Assets/returnAssets.service';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { ReturnUpdateLogicService } from './returnUpdateLogic.service';

@Component({
    selector: 'return-page',
    templateUrl: './returnPage.component.html',
    providers: [ReturnUpdateLogicService]
})
export class ReturnPageComponent implements OnInit, OnDestroy, IMutexServiceHandler {
    constructor(
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _returnService: ReturnService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _navigationService: NavigationService,
        private readonly _toastsManager: ToastrService,
        private readonly _modalService: WeissmanModalService,
        private readonly _restrictService: RestrictService,
        private readonly _helpService: HelpService,
        private readonly _longRunningProcessRepository: LongRunningProcessRepository,
        private readonly _timer: TimerService,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _filingBatchRepository: FilingBatchRepository,
        private readonly _returnUpdateLogicService: ReturnUpdateLogicService
    ) { }

    private _busyRef: BusyIndicatorRef;
    private _busyRefId = this._busyIndicatorService.generateUniqueMessageIdentifier();
    private _progressStatusSub: Subscription;
    private _progressSub: Subscription;
    private _lockChangedSub: Subscription;
    private readonly _userMessages: string[] = [
        'Gathering information',
        'Retrieving forms and reports',
        'Loading assets',
        'Summarizing data',
        'Calculating totals',
        'Checking things over',
        'Putting it all together'
    ];
    private _destroy$: Subject<void> = new Subject();
    private _filingBatchId: number;
    private _isPrepareDataLongRunningProcess: boolean;

    get company(): Core.CompanyModel { return this._returnService.company; }
    get filingBatch(): Compliance.FilingBatchModel { return this._returnService.filingBatch; }
    get parcelName(): string { return this._returnService.parcelName; }
    get parcelId(): number { return this._returnService.parcelId; }
    get canEnterEditMode(): boolean { return this._mutexService.canAcquire(this._returnService.editGroup); }
    get canEdit(): boolean { return this._returnService.canEditCompany && !this._returnService.isReturnInReadOnlyMode; }
    get canEditCompany(): boolean { return this._returnService.canEditCompany; }

    get attachmentsModel(): AttachmentModalData {
        if (!this.filingBatch) {
            return null;
        }

        return {
            entityType: 'FilingBatch',
            entityName: this.filingBatch.description,
            entityData: {
                typeId: Core.EntityTypes.FilingBatch,
                id: this.filingBatch.filingBatchId,
                name: this.filingBatch.description
            },
            singleYear: true,
            year: this.filingBatch.taxYear,
            disableYears: true,
            readOnly: !this.canEditCompany
        } as AttachmentModalData;
    }
    get commentsModel(): CommentModalData {
        if (!this.filingBatch) {
            return null;
        }

        return {
            entityID: this.filingBatch.filingBatchId,
            entityTypeID: Core.EntityTypes.FilingBatch,
            description: this.filingBatch.description,
            year: this.filingBatch.taxYear.toString(),
            canEdit: this.canEditCompany,
            parcelAcctNum: undefined,
            parcelID: undefined,
        } as CommentModalData;
    }

    get showWarning(): boolean {
        return this._returnService.compareFormsResult && !this._returnService.compareFormsResult.isDefault;
    }
    get customReturns(): Compliance.ReturnCustomReturnModel[] {
        return this._returnService.compareFormsResult.customReturns;
    }

    get attachmentsHelpContentId(): string {
        if (!this.canEnterEditMode) {
            return 'app.disabled-edit-mode';
        }

        return 'app.attachments';
    }

    get commentsHelpContentId(): string {
        if (!this.canEnterEditMode) {
            return 'app.disabled-edit-mode';
        }

        return 'app.comments';
    }

    isInitialized: boolean = false;
    breadcrumbs: BreadCrumb[] = [];

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(RETURN_PAGE_HELP);

        const filingBatchIdParam = this._routerService.getQuerystringParam('filingBatchId');

        this._filingBatchId = parseInt(filingBatchIdParam);

        await this._loadFilingBatchInfo();
        this._isPrepareDataLongRunningProcess = this.filingBatch.isPrepareDataLongRunningProcess;

        this.breadcrumbs = [
            {
                name: this.filingBatch.companyName,
                target: 'company',
                options: { companyId: this.filingBatch.companyId }
            },
            {
                name: 'Filing Batches',
                target: 'filingBatchList',
                options: { companyId: this.filingBatch.companyId }
            },
            {
                name: this.filingBatch.description,
                target: 'filingBatchDetails',
                options: {
                    companyId: this.filingBatch.companyId,
                    filingBatchId: this.filingBatch.filingBatchId
                }
            }
        ];

        await this._loadData();

        this._progressStatusSub = this._returnService.processStatus$.subscribe(x => this._handleStatusChange(x));

        this._lockChangedSub = this._mutexService.lockChanged$.subscribe((x) => {
            const isLocked = x && x[this._returnService.editGroup];
            if (isLocked) {
                this._navigationService.enableNavWarning('Editing is in progress.  Are you sure you wish to leave?');
            } else {
                this._navigationService.disableNavWarning();
            }
        });

        // get initial status and block UI if a long running process is active
        // this needs to run after the global busy indicator in the try/catch has been disposed
        this._timer.setTimeout(() => this._handleStatusChange(this._returnService.processStatus), 500);

        this.isInitialized = true;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._progressStatusSub && this._progressStatusSub.unsubscribe();
        this._progressSub && this._progressSub.unsubscribe();
        this._lockChangedSub && this._lockChangedSub.unsubscribe();
        this._returnService.stop();
        this._returnAssetsService.clearScheduleAndFactorFilter();
        this._hideBusyIndicator();
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    async refreshBatch(): Promise<void> {
        await this._loadData();
        this._productAnalyticsService.logEvent('click-batch-refresh', {});
    }

    async _loadData(): Promise<void> {
        if (!this._isPrepareDataLongRunningProcess) {
            let intervalMessageIndex: number = 0;

            const busyConfig: IBusyIndicatorConfig = {
                identifier: this._busyIndicatorService.generateUniqueMessageIdentifier(),
                title: 'Preparing Returns',
                message: this._userMessages[intervalMessageIndex]
            };
            const busyRef = this._busyIndicatorService.show(busyConfig);

            const intervalId = this._timer.setInterval(() => {
                intervalMessageIndex < this._userMessages.length
                    ? busyRef.updateMessage(this._userMessages[intervalMessageIndex++], busyConfig.identifier)
                    : clearInterval(intervalId);
            }, 2500);

            try {
                await this._returnService.prepareData(this._filingBatchId, false);
            } finally {
                clearInterval(intervalId);
                await busyRef.hide();
            }

            await this._returnUpdateLogicService.start();
            await this._validateForms();
        } else {
            this._showBusyIndicator('Preparing Returns', 'Working on it...', false, false);
            await this._returnService.startPrepareData(this._filingBatchId);
            await this._busyRef.setLongRunningProcessId(this._returnService.longRunningProcessId);
            await this._returnUpdateLogicService.start();
        }
    }

    navigateToUnauthorized(): void {
        this._routerService.go('unauthorizedAccess', {});
    }

    async viewTasks(): Promise<void> {
        await this._returnService.viewTasks();
    }

    taskError(taskError: TaskError): void {
        this._hideBusyIndicator();
        this._handleProgressError(taskError.error, taskError.message);
    }

    navigateToFilingBatchDetails(): void {
        this._routerService.go('filingBatchDetails', {
            'companyId': this.filingBatch.companyId,
            'filingBatchId': this.filingBatch.filingBatchId
        });
    }

    navigateToParcel(): void {
        this._routerService.go('parcelRedirect', {
            'entityID': this.parcelId
        });
    }

    async canUserEditReturns(): Promise<boolean> {
        return !(await this._restrictService.hasCompanyPermission(this.filingBatch.companyId, Core.AccessRightsEnum.Write)
        && this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW, this.filingBatch.instanceId));
    }

    async resetForms(): Promise<void> {
        const busyConfig: IBusyIndicatorConfig = {
            message: 'Resetting Forms'
        };
        const busyRef = this._busyIndicatorService.show(busyConfig);
        try {
            await this._returnService.resetForms(this._filingBatchId);
        } finally {
            busyRef.hide();
        }
    }

    private async _handleStatusChange(status: Compliance.FilingBatchProcessStatusEnum): Promise<void> {
        let title: string;
        let canDismiss: boolean;
        let hasAction: boolean = true;

        switch (status) {
        case Compliance.FilingBatchProcessStatusEnum.Locking:
            title = 'Locking Returns';
            canDismiss = true;
            break;
        case Compliance.FilingBatchProcessStatusEnum.Unlocking:
            title = 'Unlocking Returns';
            break;
        case Compliance.FilingBatchProcessStatusEnum.Signing:
            title = 'Signing Applicable Returns';
            break;
        case Compliance.FilingBatchProcessStatusEnum.Unsigning:
            title = 'Unsigning Applicable Returns';
            break;
        case Compliance.FilingBatchProcessStatusEnum.Finalizing:
            title = 'Finalizing Returns';
            break;
        case Compliance.FilingBatchProcessStatusEnum.PreparingData:
            title = 'Preparing Returns';
            hasAction = false;
            break;
        default:
            title = null;
            break;
        }

        if (title && !this._busyRef) {
            this._showBusyIndicator(title, 'Working on it...', canDismiss, hasAction, this._returnService.longRunningProcessId);
        }
    }

    private _showBusyIndicator(title: string, message: string = 'Working on it...', canDismiss: boolean = false, hasAction: boolean = true, longRunningProcessId?: number): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this._busyRefId);
            return;
        }

        this._busyRef = this._busyIndicatorService.show({
            identifier: this._busyRefId,
            longRunningProcessId: longRunningProcessId,
            title: title ? title : 'Processing',
            message,
            hasProgressBar: true,
            hasAction: hasAction,
            actionMessage: (canDismiss) ? 'Cancel' : 'OK',
            canDismiss
        });

        this._busyRef.onAction().pipe(takeUntil(this._destroy$)).subscribe(async () => {
            if (canDismiss && this._returnService.longRunningProcessId) {
                await lastValueFrom(this._longRunningProcessRepository.cancel(this._returnService.longRunningProcessId));
            } else {
                await this._hideBusyIndicator();
                this.navigateToFilingBatchDetails();
            }
        });

        this._busyRef.onProgressBarComplete().pipe(takeUntil(this._destroy$)).subscribe(() => {
            if (!this._returnService.company) {
                this._returnService.loadPreparedData(this._filingBatchId).then(() => {
                    this._hideBusyIndicator();
                    this._validateForms();
                });
            } else {
                this._hideBusyIndicator();
            }
        });

        this._busyRef.onDismiss().pipe(takeUntil(this._destroy$)).subscribe(() => {
            this._hideBusyIndicator();
            this.navigateToFilingBatchDetails();
        });
    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
        this._destroy$.next();
    }

    private _handleProgressError(e: any, defaultMessage: string) {
        let message = defaultMessage;
        if (e.error && e.error.message) {
            message = e.error.message;
        }

        this._toastsManager.error(message);
    }

    private async _validateForms(): Promise<void> {
        const validateFormsResult = this._returnService.validateFormsResult;
        if (validateFormsResult && !validateFormsResult.isValid) {
            const result = await this._modalService.showAsync(ReturnFormsErrorComponent, validateFormsResult, 'modal-md');

            const hasFormsInDevelopment = validateFormsResult.formErrors.some(x => x.formRevisionInDevelopment);
            const hasNoFormsAvailable = this._returnService.getAssociatedReturnFormRevisions().length === 0
                && this._returnService.getNotAssociatedReturnFormRevisions().length === 0;

            if (!result?.accepted
                || (result?.errorType === Compliance.ReturnValidateFormResultEnum.OneOrMoreFormsAreNotAvailable
                    && ((!this._restrictService.isInRole(Roles.FORMSUNDERDEVELOPMENTVIEW) && hasFormsInDevelopment) || hasNoFormsAvailable))) {
                this.navigateToFilingBatchDetails();

            }
        }
    }

    private async _loadFilingBatchInfo(): Promise<void> {
        const busyConfig: IBusyIndicatorConfig = {
            identifier: this._busyIndicatorService.generateUniqueMessageIdentifier(),
            message: 'Loading...'
        };
        const busyRef = this._busyIndicatorService.show(busyConfig);

        try {
            await this._returnService.loadFilingBatchInfo(this._filingBatchId);
        } finally {
            busyRef.hide();
        }
    }
}
